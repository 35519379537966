const getFechaUTC = () => {
	var time = new Date()
	var year = time.getUTCFullYear()
	var month = time.getUTCMonth() + 1
	var day = time.getUTCDate()
	var hour = time.getUTCHours()
	var minut = time.getUTCMinutes()
	var second = time.getUTCSeconds()

	const fechaUTC = `${year}-${month}-${day} ${hour}:${minut}:${second} GMT-0500`
	const fecha = new Date(fechaUTC)

	console.log(fechaUTC)

	return fecha
}

const getFecha = () => {
	var fecha = new Date()

	var _year = fecha.getFullYear()
	var _month = fecha.getMonth() + 1
	var _day = fecha.getDate()

	if (_month < 10) _month = '0' + _month
	if (_day < 10) _day = '0' + _day

	const fechaString = `${_year}-${_month}-${_day}`

	return fechaString
}

const getTime = () => {
	var fecha = new Date()

	var _hour = fecha.getHours()
	var _minut = fecha.getMinutes()
	var _second = fecha.getSeconds()

	if (_hour < 10) _hour = '0' + _hour
	if (_minut < 10) _minut = '0' + _minut
	if (_second < 10) _second = '0' + _second

	const hourString = `${_hour}:${_minut}:${_second}`

	return hourString
}

export { getFecha, getTime }
