// React
import { useState } from 'react'
import { authToken } from './controllers/auth'
import { useEffect } from 'react'
// Config
import { routes } from './config/routes'
// Css
import './App.css'
import './css/page.css'
import './css/formPop.css'
// Components
import Sidebar from './components/sidebar/sidebar'
// Pages
import Home from './pages/home/views/Home'
import Login from './pages/login/views/login'
import Loading from './components/Loading/loading'
import NuevaCita from './pages/cita/views/nuevaCita/NuevaCita'
import BuscarPaciente from './pages/paciente/views/buscarPaciente/BuscarPaciente'
import ListarCita from './pages/cita/views/listarCitas/ListarCita'
import VentaMedicamentos from './pages/medicamento/views/ventaMedicamentos/VentaMedicamentos'
import ListarVentaMedicamentos from './pages/medicamento/views/ListarVentaMedicamentosSinCita/ListarVentaMedicamentos'
import EditarCita from './pages/cita/views/editarCita/EditarCita'
import { getTime } from './controllers/fechaController'
import IngresosDiarios from './pages/Ingresos/views/IngresosDiarios'
import Agenda from './pages/Agenda/views/Agenda'

function App() {
	const [Route, setRoute] = useState(routes[0])
	const [LoadState, setLoadState] = useState(false)
	const [auth, setAuth] = useState(true)
	const [IdEditarCita, setEditarCita] = useState({})

	const initPage = async () => {
		// Verificamos si ha iniciado sesion
		const _auth = await authToken()
		_auth ? setLoadState(false) : setAuth(false)
	}

	useEffect(() => {
		initPage()
		var resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
		console.log('El nombre de tu zona horaria es ', resolvedOptions.timeZone)
		console.log(getTime())
	}, [auth])

	return !auth ? (
		<Login setAuth={setAuth} setLoading={setLoadState} />
	) : (
		<div className="App">
			{LoadState && <Loading />}
			<Sidebar setRoute={setRoute} Route={Route} routes={routes} setload={setLoadState} setauth={setAuth} />
			{/* === Route === */}
			{Route === 'home' && <Home setRoute={setRoute} setLoadState={setLoadState} />}
			{Route === 'nueva-cita' && <NuevaCita setRoute={setRoute} setLoadState={setLoadState} />}
			{Route === 'buscar-paciente' && <BuscarPaciente setLoadState={setLoadState} />}
			{Route === 'buscar-paciente-nuevo-paciente' && (
				<BuscarPaciente setLoadState={setLoadState} setRoute={setRoute} nuevoPaciente={true} />
			)}
			{Route === 'ver-citas' && (
				<ListarCita setRoute={setRoute} setLoadState={setLoadState} setEditarCita={setEditarCita} />
			)}
			{Route === 'venta-medicamentos' && <VentaMedicamentos setLoadState={setLoadState} setRoute={setRoute} />}
			{Route === 'ver-ventas-medicamentos' && <ListarVentaMedicamentos setLoadState={setLoadState} />}

			{/* Editar Cita */}
			{Route === 'editar-cita' && <EditarCita setLoadState={setLoadState} setRoute={setRoute} idCita={IdEditarCita} />}

			{/* Citas programadas */}
			{Route === 'citas-programadas' && <Agenda setLoadState={setLoadState} setRoute={setRoute} />}

			{/* Ingresos Diarios */}
			{Route === 'ingresos-diarios' && <IngresosDiarios setLoadState={setLoadState} setRoute={setRoute} />}
		</div>
	)
}

export default App
