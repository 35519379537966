import React from 'react'
import './PieSup.css'
import supIzq from '../img/superior/superior-izqui.png'
import supDer from '../img/superior/superior-derec.png'

import pi_1 from '../img/superior/pi-1.png'
import pin_1 from '../img/superior/pin-1.png'
import pi_2 from '../img/superior/pi-2.png'
import pin_2 from '../img/superior/pin-2.png'
import pi_3 from '../img/superior/pi-3.png'
import pin_3 from '../img/superior/pin-3.png'
import pi_4 from '../img/superior/pi-4.png'
import pin_4 from '../img/superior/pin-4.png'
import pi_5 from '../img/superior/pi-5.png'
import pin_5 from '../img/superior/pin-5.png'
import pi_6 from '../img/superior/pi-6.png'
import pin_6 from '../img/superior/pin-6.png'
import pi_7 from '../img/superior/pi-7.png'
import pin_7 from '../img/superior/pin-7.png'
import pi_8 from '../img/superior/pi-8.png'
import pin_8 from '../img/superior/pin-8.png'
import pi_9 from '../img/superior/pi-9.png'
import pin_9 from '../img/superior/pin-9.png'
import pi_10 from '../img/superior/pi-10.png'
import pin_10 from '../img/superior/pin-10.png'

import pd_1 from '../img/superior/pd-1.png'
import pdn_1 from '../img/superior/pdn-1.png'
import pd_2 from '../img/superior/pd-2.png'
import pdn_2 from '../img/superior/pdn-2.png'
import pd_3 from '../img/superior/pd-3.png'
import pdn_3 from '../img/superior/pdn-3.png'
import pd_4 from '../img/superior/pd-4.png'
import pdn_4 from '../img/superior/pdn-4.png'
import pd_5 from '../img/superior/pd-5.png'
import pdn_5 from '../img/superior/pdn-5.png'
import pd_6 from '../img/superior/pd-6.png'
import pdn_6 from '../img/superior/pdn-6.png'
import pd_7 from '../img/superior/pd-7.png'
import pdn_7 from '../img/superior/pdn-7.png'
import pd_8 from '../img/superior/pd-8.png'
import pdn_8 from '../img/superior/pdn-8.png'
import pd_9 from '../img/superior/pd-9.png'
import pdn_9 from '../img/superior/pdn-9.png'
import pd_10 from '../img/superior/pd-10.png'
import pdn_10 from '../img/superior/pdn-10.png'

const PieSup = ({ Data, setData }) => {
	


	return (
		<div className="pieSup">
			<div className="pieSupIzqu">
				<img className="piePlantillaSup" src={supIzq} alt="" />

				{/* Posterior Izquierdo */}
				<label htmlFor="pi-1">
					{!Data.pi_1 && <img className="pi_1" src={pin_1} alt="" onClick={() => setData({ ...Data, pi_1: true })} />}
					{Data.pi_1 && <img className="pi_1" src={pi_1} alt="" onClick={() => setData({ ...Data, pi_1: false })} />}
				</label>
				<label htmlFor="pi-2">
					{!Data.pi_2 && <img className="pi_2" src={pin_2} alt="" onClick={() => setData({ ...Data, pi_2: true })} />}
					{Data.pi_2 && <img className="pi_2" src={pi_2} alt="" onClick={() => setData({ ...Data, pi_2: false })} />}
				</label>
				<label htmlFor="pi-3">
					{!Data.pi_3 && <img className="pi_3" src={pin_3} alt="" onClick={() => setData({ ...Data, pi_3: true })} />}
					{Data.pi_3 && <img className="pi_3" src={pi_3} alt="" onClick={() => setData({ ...Data, pi_3: false })} />}
				</label>
				<label htmlFor="pi-4">
					{!Data.pi_4 && <img className="pi_4" src={pin_4} alt="" onClick={() => setData({ ...Data, pi_4: true })} />}
					{Data.pi_4 && <img className="pi_4" src={pi_4} alt="" onClick={() => setData({ ...Data, pi_4: false })} />}
				</label>
				<label htmlFor="pi-5">
					{!Data.pi_5 && <img className="pi_5" src={pin_5} alt="" onClick={() => setData({ ...Data, pi_5: true })} />}
					{Data.pi_5 && <img className="pi_5" src={pi_5} alt="" onClick={() => setData({ ...Data, pi_5: false })} />}
				</label>
				<label htmlFor="pi-6">
					{!Data.pi_6 && <img className="pi_6" src={pin_6} alt="" onClick={() => setData({ ...Data, pi_6: true })} />}
					{Data.pi_6 && <img className="pi_6" src={pi_6} alt="" onClick={() => setData({ ...Data, pi_6: false })} />}
				</label>
				<label htmlFor="pi-7">
					{!Data.pi_7 && <img className="pi_7" src={pin_7} alt="" onClick={() => setData({ ...Data, pi_7: true })} />}
					{Data.pi_7 && <img className="pi_7" src={pi_7} alt="" onClick={() => setData({ ...Data, pi_7: false })} />}
				</label>
				<label htmlFor="pi-8">
					{!Data.pi_8 && <img className="pi_8" src={pin_8} alt="" onClick={() => setData({ ...Data, pi_8: true })} />}
					{Data.pi_8 && <img className="pi_8" src={pi_8} alt="" onClick={() => setData({ ...Data, pi_8: false })} />}
				</label>
				<label htmlFor="pi-9">
					{!Data.pi_9 && <img className="pi_9" src={pin_9} alt="" onClick={() => setData({ ...Data, pi_9: true })} />}
					{Data.pi_9 && <img className="pi_9" src={pi_9} alt="" onClick={() => setData({ ...Data, pi_9: false })} />}
				</label>
				<label htmlFor="pi-10">
					{!Data.pi_10 && (
						<img className="pi_10" src={pin_10} alt="" onClick={() => setData({ ...Data, pi_10: true })} />
					)}
					{Data.pi_10 && (
						<img className="pi_10" src={pi_10} alt="" onClick={() => setData({ ...Data, pi_10: false })} />
					)}
				</label>
			</div>

			<div className="pieSupIzqu">
				<img className="piePlantillaSup" src={supDer} alt="" />

				{/* Posterior Derecho */}
				<input type="checkbox" id="pd-1" hidden />
				<input type="checkbox" id="pd-2" hidden />
				<input type="checkbox" id="pd-3" hidden />
				<input type="checkbox" id="pd-4" hidden />
				<input type="checkbox" id="pd-5" hidden />
				<input type="checkbox" id="pd-6" hidden />
				<input type="checkbox" id="pd-7" hidden />
				<input type="checkbox" id="pd-8" hidden />
				<input type="checkbox" id="pd-9" hidden />
				<input type="checkbox" id="pd-10" hidden />

				{/* Posterior Derecho */}
				<label htmlFor="pd-1">
					{!Data.pd_1 && <img className="pd_1" src={pdn_1} alt="" onClick={() => setData({ ...Data, pd_1: true })} />}
					{Data.pd_1 && <img className="pd_1" src={pd_1} alt="" onClick={() => setData({ ...Data, pd_1: false })} />}
				</label>
				<label htmlFor="pd-2">
					{!Data.pd_2 && <img className="pd_2" src={pdn_2} alt="" onClick={() => setData({ ...Data, pd_2: true })} />}
					{Data.pd_2 && <img className="pd_2" src={pd_2} alt="" onClick={() => setData({ ...Data, pd_2: false })} />}
				</label>
				<label htmlFor="pd-3">
					{!Data.pd_3 && <img className="pd_3" src={pdn_3} alt="" onClick={() => setData({ ...Data, pd_3: true })} />}
					{Data.pd_3 && <img className="pd_3" src={pd_3} alt="" onClick={() => setData({ ...Data, pd_3: false })} />}
				</label>
				<label htmlFor="pd-4">
					{!Data.pd_4 && <img className="pd_4" src={pdn_4} alt="" onClick={() => setData({ ...Data, pd_4: true })} />}
					{Data.pd_4 && <img className="pd_4" src={pd_4} alt="" onClick={() => setData({ ...Data, pd_4: false })} />}
				</label>
				<label htmlFor="pd-5">
					{!Data.pd_5 && <img className="pd_5" src={pdn_5} alt="" onClick={() => setData({ ...Data, pd_5: true })} />}
					{Data.pd_5 && <img className="pd_5" src={pd_5} alt="" onClick={() => setData({ ...Data, pd_5: false })} />}
				</label>
				<label htmlFor="pd-67">
					{!Data.pd_6 && <img className="pd_6" src={pdn_6} alt="" onClick={() => setData({ ...Data, pd_6: true })} />}
					{Data.pd_6 && <img className="pd_6" src={pd_6} alt="" onClick={() => setData({ ...Data, pd_6: false })} />}
				</label>
				<label htmlFor="pd-7">
					{!Data.pd_7 && <img className="pd_7" src={pdn_7} alt="" onClick={() => setData({ ...Data, pd_7: true })} />}
					{Data.pd_7 && <img className="pd_7" src={pd_7} alt="" onClick={() => setData({ ...Data, pd_7: false })} />}
				</label>
				<label htmlFor="pd-8">
					{!Data.pd_8 && <img className="pd_8" src={pdn_8} alt="" onClick={() => setData({ ...Data, pd_8: true })} />}
					{Data.pd_8 && <img className="pd_8" src={pd_8} alt="" onClick={() => setData({ ...Data, pd_8: false })} />}
				</label>
				<label htmlFor="pd-9">
					{!Data.pd_9 && <img className="pd_9" src={pdn_9} alt="" onClick={() => setData({ ...Data, pd_9: true })} />}
					{Data.pd_9 && <img className="pd_9" src={pd_9} alt="" onClick={() => setData({ ...Data, pd_9: false })} />}
				</label>
				<label htmlFor="pd-10">
					{!Data.pd_10 && (
						<img className="pd_10" src={pdn_10} alt="" onClick={() => setData({ ...Data, pd_10: true })} />
					)}
					{Data.pd_10 && (
						<img className="pd_10" src={pd_10} alt="" onClick={() => setData({ ...Data, pd_10: false })} />
					)}
				</label>
			</div>
		</div>
	)
}

export default PieSup
