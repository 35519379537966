import React from 'react'
import { MdTextFields } from 'react-icons/md'

const CbxFieldMap = ({ defValue ='', label, setVal, name, options = [], map_value, map_text }) => {
	return (
		<div className="form__TextField">
			<div className="form__TextFieldLabel">
				<MdTextFields className="iconLabel" />
				<label>{label}</label>
			</div>
			<select defaultValue={defValue} className="selectField" onChange={(i) => setVal(name, i.currentTarget.value)}>
				<option value={''}>---Seleccione---</option>
				{options.map((i,K) => (
					<option key={K} value={i[map_value]}>{map_text.map((ip) => i[ip] + ' ')}</option>
				))}
			</select>
		</div>
	)
}

export default CbxFieldMap
