import React from 'react'
import './formTextField.css'
import { MdTextFields } from 'react-icons/md'

const FormTextField = ({
	placeholder = '',
	type = 'text',
	label,
	setVal = () => {},
	name,
	value = '',
	readOnly = false,
	small = '',
	max_length = 0
}) => {
	return (
		<div className="form__TextField">
			<div className="form__TextFieldLabel">
				<MdTextFields className="iconLabel" />
				<label>
					{label}
					{small != '' && <small>{small}</small>}
				</label>
			</div>
			<input
				maxLength={max_length !== 0 ? max_length : ''}
				placeholder={placeholder}
				autoComplete="off"
				defaultValue={value}
				readOnly={readOnly}
				type={type}
				name={name}
				onChange={(e) => setVal(name, e.target.value)}
			/>
		</div>
	)
}

export default FormTextField
