import { setData } from '../../../controllers/dataController'
import { getTime } from '../../../controllers/fechaController'

const registrarCita = async (cita, setRoute, setLoadState) => {
	setLoadState(true)

	// Token empty
	if (!localStorage.getItem('token')) {
		alert('Vuelve a iniciar Sesion')
		window.location.reload()
	}

	if (cita.idPodologa === null) {
		setLoadState(false)
		return alert('El campo "Podologa" esta vacio')
	}

	// Validate
	if (cita.idPaciente === null) {
		setLoadState(false)
		return alert('El campo "Paciente" esta vacio')
	}
	if (cita.diagnostico === null) {
		setLoadState(false)
		return alert('El campo "Diagnostico" esta vacio')
	}
	if (cita.tratamiento === null) {
		setLoadState(false)
		return alert('El campo "Tratamiento" esta vacio')
	}
	if (cita.precio_trat <= 0) {
		console.log(cita)
		if (cita.paquetes.length === 0) {
			setLoadState(false)
			return alert('El campo "Precio Tratamiento" esta vacio')
		}
		var val = false
		for (let i = 0; i < cita.paquetes.length; i++) {
			if (cita.paquetes[i].use === true) val = true
		}
		if (!val) {
			setLoadState(false)
			// return alert('El campo "Precio Tratamiento" esta vacio')
		}
	}

	// Add Token
	cita.token = localStorage.getItem('token')
	// Add Precio
	cita.precio =
		parseFloat(cita.precio_trat) +
		parseFloat(cita.medicamentos.reduce((acumulador, it) => acumulador + it.cant * it.cuv, 0)) +
		parseFloat(cita.paquetes.reduce((acumulador, it) => acumulador + parseFloat(it.abono), 0))
	// Add timestamp
	cita.timestamp = cita.fecha + ' ' + getTime()

	await setData('cita', cita)

	
	setLoadState(false)
	setRoute('ver-citas')
}

export default registrarCita
