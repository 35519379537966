import React from 'react'
import './home.css'
import { AiOutlineBook, AiOutlineMedicineBox } from 'react-icons/ai'
import { RiFileAddLine, RiUserAddLine, RiFileSearchLine } from 'react-icons/ri'
import { MdOutlineFastfood } from 'react-icons/md'
import { TbReportMoney, TbReportSearch } from 'react-icons/tb'

const Home = ({ setRoute }) => {
	return (
		<div className="page homePage">
			<div className="homePageBox">
				<div className="boxButton" onClick={() => setRoute('nueva-cita')}>
					<RiFileAddLine className="boxButton__boxIcon" />
					<span className="boxButton__boxText">Nueva Cita</span>
				</div>
				<div className="boxButton" onClick={() => setRoute('buscar-paciente-nuevo-paciente')}>
					<RiUserAddLine className="boxButton__boxIcon" />
					<span className="boxButton__boxText">Nuevo Paciente</span>
				</div>
				<div className="boxButton" onClick={() => setRoute('venta-medicamentos')}>
					<AiOutlineMedicineBox className="boxButton__boxIcon" />
					<span className="boxButton__boxText">Vender Medicamentos</span>
				</div>
				<div className="boxButton" onClick={() => setRoute('ver-citas')}>
					<RiFileSearchLine className="boxButton__boxIcon" />
					<span className="boxButton__boxText">Ver Citas</span>
				</div>
				<div className="boxButton" onClick={() => setRoute('ver-ventas-medicamentos')}>
					<TbReportSearch className="boxButton__boxIcon" />
					<span className="boxButton__boxText">Ver ventas de Medicamentos</span>
				</div>
				<div className="boxButton" onClick={() => setRoute('citas-programadas')}>
					<AiOutlineBook className="boxButton__boxIcon" />
					<span className="boxButton__boxText">Citas Programadas</span>
				</div>
				<div className="boxButton" onClick={() => setRoute('ingresos-diarios')}>
					<TbReportMoney className="boxButton__boxIcon" />
					<span className="boxButton__boxText">Ingresos Diarios</span>
				</div>
			</div>
		</div>
	)
}

export default Home
