import { setData, setDataLogin } from './dataController'

export const authToken = async () => {
	// Vefirficar si token esta en local storage
	if (!localStorage.getItem('token')) {
		return false
	}

	// Consultar Token
	const resp = await setDataLogin('auth', { token: localStorage.getItem('token') })

	// Si el token es invalido
	if (resp.hasOwnProperty('error')) {
		localStorage.removeItem('token')
		return false
	}

	// Si el token es valido
	if (resp.token) {
		return true
	}
}
