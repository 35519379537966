import React, { useEffect, useState } from 'react'
import { AiFillBackward } from 'react-icons/ai'
import FormTextField from '../../../../components/formTextField/FormTextField'
import { getData, searchData } from '../../../../controllers/dataController'
import HistorialPaciente from '../historialPaciente/historialPaciente'
import NuevoPacienteForm from '../nuevoPaciente/nuevoPacienteForm'
import { RiFileAddLine } from 'react-icons/ri'
import { TbUserSearch } from 'react-icons/tb'
import EditarPacienteForm from '../editarPaciente/editarPaciente'

const BuscarPaciente = ({ setRoute, setLoadState, nuevoPaciente = false }) => {
	const [Data, setData] = useState([])
	const [ViewHistorial, setViewHistorial] = useState(false)
	const [ViewNuevoPaciente, setViewNuevoPaciente] = useState(false)
	const [ViewEditarPaciente, setViewEditarPaciente] = useState(false)

	const buscarPacientePorNombre = async (name = '', val = '') => {
		setLoadState(true)
		if (val === '') {
			const data = await getData('paciente')
			setData(data)
		}
		if (val !== '') {
			const data = await searchData('paciente', 'apellidos', val)
			setData(data)
		}
		setLoadState(false)
	}

	const initData = () => {
		setLoadState(true)
		if (nuevoPaciente === true) {
			setViewNuevoPaciente(true)
		}
		setLoadState(false)
	}

	useEffect(() => {
		initData()
		buscarPacientePorNombre()
	}, [ViewNuevoPaciente, ViewEditarPaciente])

	return (
		<div className="page">
			{ViewNuevoPaciente && (
				<NuevoPacienteForm
					setView={setViewNuevoPaciente}
					setLoadState={setLoadState}
					close={nuevoPaciente === true ? () => setRoute('buscar-paciente') : () => {}}
				/>
			)}

			{ViewEditarPaciente !== false && (
				<EditarPacienteForm Paciente={ViewEditarPaciente} setView={setViewEditarPaciente} setLoadState={setLoadState} />
			)}

			{ViewHistorial !== false && (
				<HistorialPaciente item={ViewHistorial} setView={setViewHistorial} setLoadState={setLoadState} />
			)}

			<div className="page__pageTitle">
				<TbUserSearch className="icon-links" /> <span className="pageTitle__span">Buscar Paciente</span>
			</div>

			<div className="ListarHeader">
				<FormTextField label={'Busqueda por Apellidos'} plh="Ingrese Apellidos" setVal={buscarPacientePorNombre} />
				<button
					className="btn-1"
					onClick={() => {
						buscarPacientePorNombre()
					}}>
					Limpiar
				</button>
				<button className="btn-1" onClick={() => setViewNuevoPaciente(true)}>
					Nuevo Paciente
				</button>
			</div>

			<div className="Listar">
				{/* Tabla */}
				<div className="Listar_Table">
					<table className="tableListar" border="0">
						<thead className="tableListarHead">
							<tr className="tableListarRowHeader">
								<th className="tableListarCeldaHeader">N°</th>
								<th className="tableListarCeldaHeader">Nombre</th>
								<th className="tableListarCeldaHeader">Apellido</th>
								<th className="tableListarCeldaHeader">Documento</th>
								<th className="tableListarCeldaHeader"></th>
								<th className="tableListarCeldaHeader"></th>
							</tr>
						</thead>
						<tbody className="tableListarBody">
							{Data.length === 0 && (
								<tr>
									<td colSpan={5} className="tableListarCelda_sinResultados">
										Sin resultados
									</td>
								</tr>
							)}
							{Data.map((i, j) => (
								<tr className="tableListarRow" key={j}>
									<td className="tableListarCelda">{i.idPaciente}</td>
									<td className="tableListarCelda">{i.nombre}</td>
									<td className="tableListarCelda">{i.apellidos}</td>
									<td className="tableListarCelda">{i.doc}</td>
									<td className="tableListarCelda">
										<button className="btn-1" onClick={() => setViewHistorial(i)}>
											Ver Historial
										</button>
									</td>
									<td className="tableListarCelda">
										<button
											className="btn-1"
											onClick={() => {
												setViewEditarPaciente(i)
											}}>
											Editar
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default BuscarPaciente
