import React, { useState } from 'react'
import { RiUserAddLine } from 'react-icons/ri'
import { BiSearchAlt } from 'react-icons/bi'
import NuevoPacienteForm from '../../../paciente/views/nuevoPaciente/nuevoPacienteForm'

const NcSection1 = ({ setSection, setLoadState, setPaciente, Cita, setDatosCita }) => {
	const [ViewNuevoPaciente, setViewNuevoPaciente] = useState(false)

	const nextFunctionPaciente = (_paciente) => {
		setPaciente(_paciente)
		updateTxtFields('idPaciente', _paciente.idPaciente)
		setSection(3)
	}

	const updateTxtFields = (name, val) => {
		setDatosCita({ ...Cita, [name]: val })
	}

	return (
		<div className="nc-section-1">
			{ViewNuevoPaciente && (
				<NuevoPacienteForm
					setPaciente={nextFunctionPaciente}
					setView={setViewNuevoPaciente}
					setLoadState={setLoadState}
				/>
			)}

			<div className="homePageBox ">
				<div className="boxButton" onClick={() => setViewNuevoPaciente(true)}>
					<RiUserAddLine className="boxButton__boxIcon" />
					<span className="boxButton__boxText">Nuevo Paciente</span>
				</div>
				<div className="boxButton" onClick={() => setSection(2)}>
					<BiSearchAlt className="boxButton__boxIcon" />
					<span className="boxButton__boxText">Buscar Paciente</span>
				</div>
			</div>
		</div>
	)
}

export default NcSection1
