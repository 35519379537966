import React, { useEffect, useState } from 'react'
import FormTitle from '../../../components/formTitle/FormTitle'
import FormTextField from '../../../components/formTextField/FormTextField'
import CbxFieldMap from '../../../components/CbxFieldMap/CbxFieldMap'
import { getData, setData } from '../../../controllers/dataController'
import { getFecha } from '../../../controllers/fechaController'
import CbxField from '../../../components/CbxField/CbxField'

const AddIngreso = ({ setLoadeState, setView }) => {
	const [PodologaData, setPodologaData] = useState([])
	const [DataIngreso, setDataIngreso] = useState({
		tipoPago: '',
		descripcion: '',
		costo: 0,
		idPodologa: '',
		timestamp: getFecha()
	})

	const crearIngreso = async () => {
		if (DataIngreso.timestamp === '') return alert('Seleccione Fecha')
		if (DataIngreso.idPodologa === '') return alert('Seleccione Podologa/Masajista')
		if (DataIngreso.descripcion === '') return alert('La descripcion no debe ser vacio!')
		if (DataIngreso.tipoPago === '') return alert('El tipo de pago no debe ser vacio!')
		if (DataIngreso.costo <= 0) return alert('El costo no debe ser vacio!')
		setLoadeState(true)

		const resp = await setData('ingreso', DataIngreso)

		setView(false)
		setLoadeState(false)
	}

	const setVal = (name, val) => setDataIngreso({ ...DataIngreso, [name]: val })

	const initData = async () => {
		setLoadeState(true)
		// Data Select
		const dataPod = await getData('podologa')
		setPodologaData(dataPod)
		setLoadeState(false)
	}

	useEffect(() => {
		initData()
	}, [])

	return (
		<div className="formPopBkg">
			<div className="form formPop">
				{/* Title */}
				<FormTitle setView={setView} title={'Nuevo Ingreso'} />

				<div className="formBody">
					{/* Fecha */}
					<FormTextField label={'Fecha'} name={'timestamp'} setVal={setVal} type="date" value={getFecha()} />

					{/*  Podologa select */}
					{PodologaData.length > 0 && (
						<CbxFieldMap
							label={'Podologa'}
							name={'idPodologa'}
							map_text={['nombre', 'apellido']}
							map_value={'idPodologa'}
							setVal={setVal}
							options={PodologaData}
						/>
					)}

					{/* DEscripcion */}
					<FormTextField label={'Descripcion'} name={'descripcion'} setVal={setVal} />

					{/*  Tipo Pago select */}
					<CbxField
						label={'Tipo de Pago'}
						name={'tipoPago'}
						setVal={setVal}
						options={['Efectivo', 'Yape', 'Transferencia', 'POS']}
					/>

					{/* Costo */}
					<FormTextField label={'Costo'} name={'costo'} type="number" setVal={setVal} />

					{/* Action Buttons */}
					<div className="form__groupBtns">
						<button
							className="form__btnCancel"
							onClick={() => {
								setView(false)
							}}>
							Cancelar
						</button>
						<button className="form__btnSuccess" onClick={() => crearIngreso()}>
							Confirmar
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AddIngreso
