import React, { useEffect, useRef, useState } from 'react'
import { AiFillPlusCircle } from 'react-icons/ai'
import { IoCloseCircleSharp } from 'react-icons/io5'
import { MdDeleteOutline, MdTextFields } from 'react-icons/md'
import { RiFileAddLine } from 'react-icons/ri'
import { getData, searchData, setData } from '../../../../controllers/dataController'
import { HiOutlineEye } from 'react-icons/hi'
import './ListarCita.css'
import DetalleCita from '../detalleCita/DetalleCita'
import TxtField from '../../../../components/TxtField/TxtField'
import { getFecha } from '../../../../controllers/fechaController'

const ListarCita = ({ setLoadState, setEditarCita, setRoute }) => {
	const [ListaCitas, setListaCitas] = useState([])
	const [Pagar, setPagar] = useState(false)
	const [Detalle, setDetalle] = useState(false)
	const [Fecha, setFecha] = useState(getFecha())

	const getCitas = async () => {
		setLoadState(true)
		const data = await searchData('cita', 'timestamp', Fecha)
		console.log(data)
		setListaCitas(data)
		setLoadState(false)
	}

	const editarCita = (i) => {
		setEditarCita(i)
		setRoute('editar-cita')
	}

	const deleteCita = async (_id) => {
		console.log(_id)
	}

	useEffect(() => {
		getCitas()
	}, [Pagar, Fecha])

	return (
		<div className="page">
			{Pagar !== false && <PagarCita item={Pagar} setView={setPagar} />}
			{Detalle !== false && (
				<DetalleCita editarCita={editarCita} idCita={Detalle} setView={setDetalle} setLoadState={setLoadState} />
			)}

			{/* Title Page */}
			<div className="page__pageTitle">
				<RiFileAddLine className="icon-links" /> <span className="pageTitle__span">Citas</span>
			</div>

			<div className="Listar">
				{/* Header */}
				<div className="Listar__Header">
					<h2 className="Listar__Header__Title">Tabla de Citas</h2>
					<div className="Listar__Header__Right">
						<div className="Listar__Header__InputSearch">
							<TxtField
								type="date"
								label={'Filtrar por Fecha'}
								name={'fecha'}
								defaultVal={Fecha}
								setVal={(name, val) => {
									setFecha(val)
								}}
							/>
						</div>
					</div>
				</div>

				{/* Tabla */}
				<div className="Listar_Table">
					<table className="tableListar" border="0">
						<thead className="tableListarHead">
							<tr className="tableListarRowHeader">
								<th className="tableListarCeldaHeader idCelda">Id</th>
								<th className="tableListarCeldaHeader">Fecha</th>
								<th className="tableListarCeldaHeader">Hora</th>
								<th className="tableListarCeldaHeader">Paciente</th>
								<th className="tableListarCeldaHeader">Podologa</th>
								<th className="tableListarCeldaHeader">Diagnostico</th>
								<th className="tableListarCeldaHeader">Tratamiento</th>
								<th className="tableListarCeldaHeader">Observaciones</th>
								<th className="tableListarCeldaHeader">Precio</th>
								<th className="tableListarCeldaHeader">Tipo de Pago</th>
								<th className="tableListarCeldaHeader">Estado</th>
								<th className="tableListarCeldaHeader">Detalle</th>
								{/* <th className="tableListarCeldaHeader">Borrar</th> */}
							</tr>
						</thead>
						<tbody className="tableListarBody">
							{ListaCitas.length === 0 && (
								<tr>
									<td colSpan={5} className="tableListarCelda_sinResultados">
										Sin resultados
									</td>
								</tr>
							)}
							{ListaCitas.map((i, j) => (
								<tr className="tableListarRow" key={j}>
									<td className="tableListarCelda idCelda">{i.idCita}</td>
									<td className="tableListarCelda fechaCelda">{i.timestamp.split(' ')[0]}</td>
									<td className="tableListarCelda">
										{i.timestamp.split(' ')[1].split(':')[0] + ':' + i.timestamp.split(' ')[1].split(':')[1]}
									</td>
									<td className="tableListarCelda">{i.paciente}</td>
									<td className="tableListarCelda">{i.podologa}</td>
									<td className="tableListarCelda">{i.diagnostico}</td>
									<td className="tableListarCelda">{i.tratamiento}</td>
									<td className="tableListarCelda">{i.obs}</td>
									<td className="tableListarCelda">{i.precio > 0 ? 'S/' + i.precio : 'No Aplica'}</td>
									<td className="tableListarCelda">{i.metodoPago === null ? '-' : i.metodoPago}</td>
									<td className="tableListarCelda">
										{i.estadoPago ? (
											<div className="epago">Pagado</div>
										) : (
											<div className="epagoButton" onClick={() => setPagar(i)}>
												Pagar
											</div>
										)}
									</td>
									<td className="tableListarCelda">
										<div className="verDetalle" onClick={() => setDetalle(i.idCita)}>
											<HiOutlineEye />
										</div>
									</td>
									{/* <td className="tableListarCelda">
										<div className="verDetalle" onClick={() => deleteCita(i.idCita)}>
											<MdDeleteOutline />
										</div>
									</td> */}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default ListarCita

const PagarCita = ({ item, setView }) => {
	const [DataE, setDataE] = useState({ metodoPago: '' })

	const Pagar = async () => {
		if (DataE.metodoPago === '') return alert('Seleccione un metodo de pago')
		const dataset = {
			...DataE,
			token: localStorage.getItem('token'),
			idCita: item.idCita
		}
		const resp = await setData('cita/pagar', dataset)
		console.log(resp)
		setView(false)
	}

	return (
		<div className="bkg-nPC">
			<div className="nEntrada form formPop">
				{/* Title */}
				<div className="form__title">
					<AiFillPlusCircle className="form__titleIcon" />
					<span className="form__titleText">Pagar</span>
					<button className="form__titleActionClose" onClick={() => setView(false)}>
						<IoCloseCircleSharp />
					</button>
				</div>

				<div className="formBody">
					{/* ------------------ */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>TOTAL a PAGAR:</label>
						</div>
						<input type="number" className="ninputT" readOnly value={item.precio} />
					</div>
					{/* ------------------ */}

					{/* ------------------ */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>Medio de Pago:</label>
						</div>
						<select onChange={(e) => setDataE({ ...DataE, metodoPago: e.target.value })}>
							<option value={''}>--- Seleccione ---</option>
							<option value="efectivo">Efectivo</option>
							<option value="yape">Yape</option>
							<option value="transferencia">Transferencia</option>
							<option value="POS">POS</option>
						</select>
					</div>
					{/* ------------------ */}

					{/* Action Buttons */}
					<div className="form__groupBtns">
						<button className="form__btnCancel" onClick={() => setView(false)}>
							Cancelar
						</button>
						<button className="form__btnSuccess" onClick={() => Pagar()}>
							Confirmar Pago
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
