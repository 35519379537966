import { getApi } from '../config/api'
import axios from 'axios'

const headerToken = () => {
	if (localStorage.getItem('token') === null) {
		alert('La sesion se ha acabado. \n Si se han enviado datos no han sido guardados. \n Vuelva a Iniciar sesion.')
		window.location.reload()
		return 0
	}
	const token = localStorage.getItem('token')

	return token
}

const getData = async (tabla = '') => {
	try {
		const token = headerToken()
		if (token === 0) return []
		if (tabla === '') return []
		const api = getApi() + 'api/' + tabla
		const resp = await (await axios.get(api, { headers: { Authorization: token } })).data
		if (!resp) return []
		return resp
	} catch (error) {
		// window.location.reload()
		return []
	}
}

const getFree = async (ruta) => {
	try {
		const token = headerToken()
		if (token === 0) return []
		if (ruta === '') return []
		const api = getApi() + 'api/' + ruta
		const resp = await (await axios.get(api, { headers: { Authorization: token } })).data
		if (!resp) return []
		return resp
	} catch (error) {
		// window.location.reload()
		return []
	}
}

const getDataById = async (tabla = '', id = '') => {
	try {
		const token = headerToken()
		if (token === 0) return []
		if (tabla === '') return {}
		if (id === '') return {}
		const api = getApi() + 'api/' + tabla + '/' + id
		const resp = await (await axios.get(api, { headers: { Authorization: token } })).data
		if (!resp) return []
		return resp
	} catch (error) {
		// window.location.reload()
		return []
	}
}

const setData = async (tabla = '', data = []) => {
	try {
		console.log(data)
		const token = headerToken()
		if (token === 0) return []
		if (tabla === '') return false
		if (data.length === 0) return false
		const api = getApi() + 'api/' + tabla
		const resp = await axios.post(api, data, { headers: { Authorization: token } })
		if (!resp.data) {
			return { message: resp.statusText }
		}
		return resp.data
	} catch (error) {
		console.log(error)
		if (error.response.data.error === 'Token invalido.') {
			alert('La sesion se ha acabado. \n Si se han enviado datos no han sido guardados. \n Vuelva a Iniciar sesion.')
			return window.location.reload()
		}
		if (error.code === 'ERR_BAD_REQUEST') return error.response.data
	}
}

const setDataLogin = async (tabla = '', data = []) => {
	if (tabla === '') return false
	if (data.length === 0) return false
	const api = getApi() + 'api/' + tabla
	try {
		const resp = await axios.post(api, data)
		if (!resp.data) {
			return { message: resp.statusText }
		}
		return resp.data
	} catch (error) {
		if (error.code === 'ERR_BAD_REQUEST') return error.response.data
	}
}

const updateData = async (tabla = '', data = [], id) => {
	try {
		const token = headerToken()
		if (tabla === '') return false
		if (id === '') return false
		if (data.length === 0) return false
		if (token === 0) return []
		const api = getApi() + 'api/' + tabla + '/' + id
		const resp = await axios.put(api, data, { headers: { Authorization: token } })
		if (!resp.data) {
			if (resp.status === 204) return alert('Registro Actualizado')
			if (resp.status === 201) return alert('Registro Actualizado')
		}
		return resp.data
	} catch (error) {
		if (error.response.data.error === 'Token invalido.') {
			alert('La sesion se ha acabado. \n Si se han enviado datos no han sido guardados. \n Vuelva a Iniciar sesion.')
			return window.location.reload()
		}
		if (error.code === 'ERR_BAD_REQUEST') return error.response.data
	}
}

const deleteData = async (tabla = '', id) => {
	try {
		if (tabla === '') return false
		if (id === '') return false
		const token = headerToken()
		if (token === 0) return []
		const api = getApi() + 'api/' + tabla + '/' + id
		const resp = await axios.delete(api, { headers: { Authorization: token } })
		if (!resp.data) {
			if (resp.status === 204) return alert('Registro Borrado')
		}
		return resp.data
	} catch (error) {
		if (error.response.data.error === 'Token invalido.') {
			alert('La sesion se ha acabado. \n Si se han enviado datos no han sido guardados. \n Vuelva a Iniciar sesion.')
			return window.location.reload()
		}
		if (error.code === 'ERR_BAD_REQUEST') return error.response.data
	}
}

const searchData = async (tabla = '', field = '', value = '') => {
	try {
		const token = headerToken()
		if (token === 0) return []
		if ((tabla === '', field === '', value === '')) return []
		const api = getApi() + 'api/' + tabla + '/search/' + field + '/' + value
		const resp = await (await axios.get(api, { headers: { Authorization: token } })).data
		if (!resp) return []
		return resp
	} catch (error) {
		// window.location.reload()
		return []
	}
}

export { getData, setData, searchData, getDataById, updateData, deleteData, getFree, setDataLogin }
