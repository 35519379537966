import React, { useEffect, useState } from 'react'
import './listCart.css'
import { BsFillXCircleFill } from 'react-icons/bs'
import { IoAddOutline } from 'react-icons/io5'
import { AiOutlineLine } from 'react-icons/ai'

const ListCart = ({ Data, setD = () => {} }) => {
	const [DataD, setData] = useState(Data)

	useEffect(() => {}, [Data])

	return (
		<div className="listCart">
			{Data.length > 0 ? (
				Data.map((q, w) => (
					<div key={w} className="listCartItem">
						{/* Detalle Med */}
						<div className="listCartItem__data">
							<span className="listCartItem__name">{q.medicamento}</span>
							<span className="listCartItem__cuv">S/{q.cuv}</span>
						</div>

						{/* Add cantidad */}
						<div className="listCartItem__action">
							<AiOutlineLine
								className="listCartItem__action__Act listCartItem__action__ActDown"
								onClick={() => setD(q, q.cant - 1)}
							/>
							<span className="listCartItem__action__cant">{q.cant}</span>
							<IoAddOutline
								className="listCartItem__action__Act listCartItem__action__ActAdd"
								onClick={() => setD(q, q.cant + 1)}
							/>
						</div>

						{/* Eliminar Med */}
						<BsFillXCircleFill className="listCartItem__delete" onClick={() => setD('delete', w)} />
					</div>
				))
			) : (
				<div className="listWithCategories__itemListProduct__empty">No has seleccionado ningun medicamento</div>
			)}
		</div>
	)
}

export default ListCart
