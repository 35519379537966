import React, { useEffect, useState } from 'react'
import FormTextField from '../../../../components/formTextField/FormTextField'
import { getData, searchData } from '../../../../controllers/dataController'
import { AiFillBackward } from 'react-icons/ai'

const NcSection2 = ({ setSection, setPaciente, setLoadState, Cita, setDatosCita }) => {
	const [Data, setData] = useState([])

	const buscarPacientePorNombre = async (name = '', val = '') => {
		if (val === '') {
			setLoadState(true)
			const data = await getData('paciente')
			setData(data)
		}

		if (val !== '') {
			const data = await searchData('paciente', 'apellidos', val)
			setData(data)
		}
		setLoadState(false)
	}

	const setPac = (i) => {
		setPaciente(i)
		setSection(3)
		setDatosCita({ ...Cita, idPaciente: i.idPaciente })
	}

	useEffect(() => {
		buscarPacientePorNombre()
	}, [])

	return (
		<div className="nc-section-2">
			<div className="ListarHeader">
				<FormTextField label={'Busqueda por Apellidos'} plh="Ingrese Apellidos" setVal={buscarPacientePorNombre} />
				<button
					className="btn-1"
					onClick={() => {
						buscarPacientePorNombre()
					}}>
					Limpiar
				</button>
				<button className="btn-2" onClick={() => setSection(1)}>
					<AiFillBackward className="btn-2-icon" />
					Atras
				</button>
			</div>

			<div className="Listar">
				{/* Tabla */}
				<div className="Listar_Table">
					<table className="tableListar" border="0">
						<thead className="tableListarHead">
							<tr className="tableListarRowHeader">
								<th className="tableListarCeldaHeader">N°</th>
								<th className="tableListarCeldaHeader">Nombre</th>
								<th className="tableListarCeldaHeader">Apellido</th>
								<th className="tableListarCeldaHeader">Documento</th>
								<th className="tableListarCeldaHeader"></th>
							</tr>
						</thead>
						<tbody className="tableListarBody">
							{Data.length === 0 && (
								<tr>
									<td colSpan={5} className="tableListarCelda_sinResultados">
										Sin resultados
									</td>
								</tr>
							)}
							{Data.map((i, j) => (
								<tr className="tableListarRow" key={j}>
									<td className="tableListarCelda">{i.idPaciente}</td>
									<td className="tableListarCelda">{i.nombre}</td>
									<td className="tableListarCelda">{i.apellidos}</td>
									<td className="tableListarCelda">{i.doc}</td>
									<td className="tableListarCelda">
										<button className="btn-1" onClick={() => setPac(i)}>
											Seleccionar
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default NcSection2
