import React, { useState } from 'react'
import './sidebar.css'
import { AiOutlineMenu, AiOutlineMedicineBox, AiOutlineBook } from 'react-icons/ai'
import { FiArrowLeft } from 'react-icons/fi'
import { FaHome } from 'react-icons/fa'
import { RiFileAddLine, RiUserAddLine, RiFileSearchLine } from 'react-icons/ri'
import { MdOutlineFastfood } from 'react-icons/md'
import { TbReportMoney, TbReportSearch, TbUserSearch } from 'react-icons/tb'
import { BiExit } from 'react-icons/bi'
import LogoV from './logo-v.png'
import LogoVST from './logo-v-st.png'

const Sidebar = ({ setRoute, Route, setload, setauth }) => {
	const [SidebarCrl, setSidebarCrl] = useState(false)

	const movePage = (page) => {
		setRoute(page)
		setSidebarCrl(false)
	}

	return (
		<div
			className={SidebarCrl ? 'sidebar sidebar-open' : 'sidebar sidebar-close'}
			style={{ backgroundImage: 'url(/img/bkg-sidebar.png)' }}>
			{/* Btn Open Sidebarl; */}
			<button
				className={SidebarCrl ? 'sidebarBtnCtrl sBtnExpanded' : 'sidebarBtnCtrl'}
				onClick={() => setSidebarCrl(!SidebarCrl)}>
				{SidebarCrl ? <FiArrowLeft /> : <AiOutlineMenu />}
			</button>

			<img src={SidebarCrl ? LogoV : LogoVST} className="sidebar-logo" alt="" />

			<div className="sidebarUl">
				<div className={Route === 'home' ? 'btn-nav-li active' : 'btn-nav-li'} onClick={() => movePage('home')}>
					<FaHome className="icon-links" /> <span>Menu</span>
				</div>

				<div
					className={Route === 'nueva-cita' ? 'btn-nav-li active' : 'btn-nav-li'}
					onClick={() => movePage('nueva-cita')}>
					<RiFileAddLine className="icon-links" /> <span>Nueva Cita</span>
				</div>

				<div
					className={Route === 'nuevo-paciente' ? 'btn-nav-li active' : 'btn-nav-li'}
					onClick={() => movePage('buscar-paciente')}>
					<TbUserSearch className="icon-links" /> <span>Buscar Paciente</span>
				</div>

				<div
					className={Route === 'venta-medicamentos' ? 'btn-nav-li active' : 'btn-nav-li'}
					onClick={() => movePage('venta-medicamentos')}>
					{' '}
					<AiOutlineMedicineBox className="icon-links" /> <span>Vender Medicamentos</span>{' '}
				</div>

				<div
					className={Route === 'ver-citas' ? 'btn-nav-li active' : 'btn-nav-li'}
					onClick={() => movePage('ver-citas')}>
					<RiFileSearchLine className="icon-links" /> <span>Ver Citas</span>{' '}
				</div>

				<div
					className={Route === 'ver-ventas-medicamentos' ? 'btn-nav-li active' : 'btn-nav-li'}
					onClick={() => movePage('ver-ventas-medicamentos')}>
					<TbReportSearch className="icon-links" /> <span>Ver Ventas de Medicamentos</span>{' '}
				</div>

				<div
					className={Route === 'citas-programadas' ? 'btn-nav-li active' : 'btn-nav-li'}
					onClick={() => movePage('citas-programadas')}>
					<AiOutlineBook className="icon-links" /> <span>Citas Programadas</span>{' '}
				</div>

				<div
					className={Route === 'ingresos-diarios' ? 'btn-nav-li active' : 'btn-nav-li'}
					onClick={() => movePage('ingresos-diarios')}>
					<TbReportMoney className="icon-links" /> <span>Ingresos Diarios</span>{' '}
				</div>

				{/* Cerrar Sesion */}
			</div>
			<div
				className="btn-nav-li cerrar-sesion-bottom"
				onClick={() => {
					setload(true)
					localStorage.clear()
					setauth(false)
					setload(false)
				}}>
				<BiExit className="icon-links" /> <span>Cerrar Sesión</span>{' '}
			</div>
		</div>
	)
}

export default Sidebar
