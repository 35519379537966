import React, { useRef, useState } from 'react'
import './Login.css'
import logo from '../assets/logo.png'
import bkgLoginRight from '../assets/bkg-login.png'
import { BsFillPersonFill } from 'react-icons/bs'
import { BiLockAlt } from 'react-icons/bi'
import { HiEye } from 'react-icons/hi'
import { setData, setDataLogin } from '../../../controllers/dataController'

const Login = ({ setAuth, setLoading }) => {
	const refUsuario = useRef(null)
	const refPassword = useRef(null)

	const [TypePass, setTypePass] = useState(false)

	const auth = async () => {
		// Pantalla de Carga
		setLoading(true)

		// Recolectar Datos
		const data = {
			usuario: refUsuario.current.value,
			password: refPassword.current.value
		}

		// Enviar Datos
		const resp = await setDataLogin('login', data)

		// Validar Error
		if (resp.error) {
			alert(resp.error)
			setLoading(false)
		}

		// Crear el token
		localStorage.setItem('token', resp.token)

		// Ingresar al menu
		if (resp.token) setAuth(true)

		setLoading(false)
	}

	return (
		<div id="Login">
			<div className="FormLogin">
				<img src={logo} alt="PodoYimFeet" className="logoFormLogin" />
				<h3>Hola de nuevo!</h3>
				<p>Por favor, ingresa tus credenciales para ingresar a la plataforma</p>

				<div className="TextField">
					<label>Usuario</label>
					<BsFillPersonFill className="icon-l" />
					<input type="text" ref={refUsuario} />
				</div>

				<div className="TextField Textfield__password">
					<label>Contraseña</label>
					<HiEye className="icon-r" onClick={() => setTypePass(!TypePass)} />
					<BiLockAlt className="icon-l" />
					<input type={TypePass ? 'text' : 'password'} ref={refPassword} />
				</div>

				{/* <div className="chbxLabel">
					<input type="checkbox" id="recuerdame" />
					<label htmlFor="recuerdame">&nbsp; Recuerdame</label>
				</div> */}

				<button className="buttonLogin" onClick={auth}>
					Ingresar
				</button>

				<footer>PodoYimFeet™ 2023. Todos los derechos reservados</footer>
			</div>

			{/* BKG Right */}
			<div className="bkgLoginRight">
				<img src={bkgLoginRight} alt="" />
			</div>
		</div>
	)
}

export default Login
