import React, { useEffect, useState } from 'react'
import { HiOutlineEye } from 'react-icons/hi'
import TxtField from '../../../components/TxtField/TxtField'
import { RiFileAddLine } from 'react-icons/ri'
import { deleteData, searchData, updateData } from '../../../controllers/dataController'
import { getFecha } from '../../../controllers/fechaController'
import AddAgenda from './AddAgenda'
import { MdTextFields } from 'react-icons/md'
import EditAgenda from './EditAgenda'
import { BsWhatsapp } from 'react-icons/bs'

const Agenda = ({ setRoute, setLoadState }) => {
	const [ListaAgendas, setListaAgendas] = useState([])
	const [Fecha, setFecha] = useState(getFecha())
	const [NewAgenda, setNewAgenda] = useState(false)
	const [EditarAgenda, setEditAgenda] = useState(false)

	const getAgendas = async () => {
		setLoadState(true)
		const data = await searchData('agenda', 'fecha', Fecha)
		console.log(data)
		setListaAgendas(data)
		setLoadState(false)
	}

	const deleteAgenda = async (idAgenda) => {
		if (idAgenda === '') return 0
		const opcion = window.confirm('¿Desea Borrar el Registro con el ID: ' + idAgenda + '? ')
		if (opcion === true) {
			setLoadState(true)
			await deleteData('agenda', idAgenda)
			getAgendas()
		}
	}

	const updateEstado = async (item, estado) => {
		setLoadState(true)
		await updateData('agenda-estado', { estado: estado }, item.id)
		getAgendas()
	}

	useEffect(() => {
		getAgendas()
	}, [Fecha, NewAgenda, EditarAgenda])

	return (
		<div className="page">
			{NewAgenda !== false && <AddAgenda setLoadeState={setLoadState} setView={setNewAgenda} />}
			{EditarAgenda !== false && (
				<EditAgenda item={EditarAgenda} setLoadeState={setLoadState} setView={setEditAgenda} />
			)}

			{/* Title Page */}
			<div className="page__pageTitle">
				<RiFileAddLine className="icon-links" /> <span className="pageTitle__span">Citas Programadas</span>
			</div>

			<div className="Listar">
				{/* Header */}
				<div className="Listar__Header">
					<button
						className="btn-1"
						style={{ marginLeft: 'auto', marginBottom: '40px' }}
						onClick={() => setNewAgenda(true)}>
						Agendar Cita
					</button>

					<div className="Listar__Header__Right" style={{}}>
						<div className="Listar__Header__InputSearch">
							<TxtField
								type="date"
								label={'Filtrar por Fecha'}
								name={'fecha'}
								defaultVal={Fecha}
								setVal={(name, val) => {
									setFecha(val)
								}}
							/>
						</div>
					</div>
				</div>

				{/* Tabla */}
				<div className="Listar_Table">
					<table className="tableListar" border="0">
						<thead className="tableListarHead">
							<tr className="tableListarRowHeader">
								<th className="tableListarCeldaHeader">Paciente</th>
								<th className="tableListarCeldaHeader">Fecha Programada</th>
								<th className="tableListarCeldaHeader">Celular</th>
								<th className="tableListarCeldaHeader">Estado</th>
								<th className="tableListarCeldaHeader"></th>
								{/* <th className="tableListarCeldaHeader"></th> */}
								<th className="tableListarCeldaHeader"></th>
							</tr>
						</thead>
						<tbody className="tableListarBody">
							{ListaAgendas.length === 0 && (
								<tr>
									<td colSpan={5} className="tableListarCelda_sinResultados">
										Sin resultados
									</td>
								</tr>
							)}
							{ListaAgendas.map((i, j) => (
								<tr className="tableListarRow" key={j}>
									<td className="tableListarCelda">{i.paciente}</td>
									<td className="tableListarCelda fechaCelda">{i.fecha.split(' ')[0]}</td>
									<td className="tableListarCelda">{i.celular}</td>
									<td className="tableListarCelda">
										{i.estado === 'Sin Avisar' && (
											<div className="epagoButton" onClick={() => updateEstado(i, 'Avisado')}>
												Sin Avisar
											</div>
										)}
										{i.estado === 'Avisado' && (
											<div
												className="epago"
												style={{ background: 'blue', cursor: 'pointer' }}
												onClick={() => updateEstado(i, 'Realizado')}>
												Avisado
											</div>
										)}
										{i.estado === 'Realizado' && (
											<div
												className="epago"
												style={{ cursor: 'pointer' }}
												onClick={() => updateEstado(i, 'Sin Avisar')}>
												Realizado
											</div>
										)}
									</td>
									<td className="tableListarCelda" style={{ textAlign: 'center' }}>
										<a
											href={
												'https://api.whatsapp.com/send?phone=51' +
												i.celular +
												'&text=Hola%2C%20te%20habla%20el%20centro%20de%20podologia%20y%20masajes%20PODOYIMFEET%2C%20le%20hacemos%20recordar%20que%20tiene%20una%20cita%20programada%20para%20el%20dia%20de%20hoy%2C%20esperamos%20su%20visita%20%F0%9F%98%84'
											}
											target="_blank"
											rel="noreferrer"
											style={{
												display: 'flex',
												flexFlow: 'column',
												alignItems: 'center',
												textDecoration: 'none',
												color: 'black'
											}}>
											<BsWhatsapp />
											Enviar un WhatsApp
										</a>
									</td>
									{/* <td>
										<button
											className="btn-1"
											onClick={() => {
												setEditAgenda(i)
											}}>
											Editar
										</button>
									</td> */}
									<td>
										<button
											style={{ background: '#fa4d4d', marginLeft: '10px' }}
											className="btn-1"
											onClick={() => deleteAgenda(i.id)}>
											Borrar
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default Agenda
