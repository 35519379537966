import React, { useEffect, useState } from 'react'
import NcSection1 from './nc-section-1'
import NcSection2 from './nc-section-2'
import NcSection3 from './nc-section-3'
import './nuevaCita.css'
import { RiFileAddLine } from 'react-icons/ri'
import { AiFillBackward } from 'react-icons/ai'
import registrarCita from '../../controllers/registrarCita'
import { getFecha, getTime } from '../../../../controllers/fechaController'

const NuevaCita = ({ setRoute, setLoadState }) => {
	const [Section, setSection] = useState(1)
	const [Paciente, setPaciente] = useState(false)

	const [Cita, setDatosCita] = useState({
		idPaciente: null,
		diagnostico: null,
		tratamiento: null,
		obs: null,
		inferior: null,
		posterior: null,
		precio_trat: 0,
		precio: 0,
		idPodologa: null,
		medicamentos: [],
		paquetes: [],
		fecha: getFecha()
	})

	useEffect(() => {}, [])

	return (
		<div className="page nuevaCita">
			{/* Title Page */}
			<div className="page__pageTitle">
				<RiFileAddLine className="icon-links" /> <span className="pageTitle__span">Nueva Cita</span>
			</div>

			{/* Sections */}
			{Section === 1 && (
				<NcSection1
					Cita={Cita}
					setDatosCita={setDatosCita}
					setPaciente={setPaciente}
					setSection={setSection}
					setLoadState={setLoadState}
				/>
			)}
			{Section === 2 && (
				<NcSection2
					Cita={Cita}
					setDatosCita={setDatosCita}
					setSection={setSection}
					setPaciente={setPaciente}
					setLoadState={setLoadState}
				/>
			)}
			{Section === 3 && (
				<NcSection3
					setSection={setSection}
					Paciente={Paciente}
					Cita={Cita}
					setDatosCita={setDatosCita}
					setLoadState={setLoadState}
				/>
			)}

			{/* Actions Page */}
			{Section === 3 && (
				<div className="actionsPage">
					{/* Atras */}
					<button className="btn-2" onClick={() => setSection(2)}>
						<AiFillBackward className="btn-2-icon" />
						Atras
					</button>

					{/* Total */}
					<div className="DatosCita__Total flex row">
						<label className="DatosCitaTotal__label">TOTAL</label>
						<div className="DatosCitaTotal__DivInput ">
							<span className="DatosCitaTotal__prec">S/</span>
							<input
								value={
									parseFloat(Cita.precio_trat === '' ? 0 : Cita.precio_trat) +
									parseFloat(Cita.medicamentos.reduce((acumulador, it) => acumulador + it.cant * it.cuv, 0)) +
									parseFloat(Cita.paquetes.reduce((acumulador, it) => acumulador + parseFloat(it.abono), 0))
								}
								readOnly
								className="DatosCitaTotal__TotalIn"
							/>
						</div>
					</div>

					{/* Registrar */}
					<button className="btn-1" onClick={() => registrarCita(Cita, setRoute, setLoadState)}>
						Registrar
					</button>
				</div>
			)}
		</div>
	)
}

export default NuevaCita
