import React, { useEffect, useState } from 'react'
import { TbReportSearch } from 'react-icons/tb'
import { deleteData, getData, getDataById } from '../../../../controllers/dataController'
import { IoCloseCircleSharp } from 'react-icons/io5'

const ListarVentaMedicamentos = ({ setLoadState }) => {
	// const BusquedaInput = useRef(null)
	const [ListaCitas, setListaCitas] = useState([])
	const [Pagar, setPagar] = useState(false)
	const [Detalle, setDetalle] = useState(false)

	const getCitas = async () => {
		setLoadState(true)
		// const buscar = BusquedaInput.current.value
		const data = await getData('medicamento/ventas')
		setListaCitas(data)
		setLoadState(false)
	}

	const deleteVenta = async (idReceta) => {
		const opcion = window.confirm('¿Desea Borrar el Registro con el ID: ' + idReceta + '? ')
		if (opcion === true) {
			setLoadState(true)
			const resp = await deleteData('medicamento/ventas', idReceta)
			console.log(resp)
			setLoadState(false)
			await getCitas()
		}
	}

	useEffect(() => {
		getCitas()
	}, [Pagar])

	return (
		<div className="page">
			{Detalle !== false && <DetalleVenta setView={setDetalle} idReceta={Detalle} setLoadState={setLoadState} />}

			<div className="page__pageTitle">
				<TbReportSearch className="icon-links" /> <span className="pageTitle__span">Venta de Medicamentos</span>
			</div>

			<div className="Listar">
				{/* Header */}
				<div className="Listar__Header">
					<div className="Listar__Header__Right">
						<div className="Listar__Header__InputSearch">
							{/* <input onChange={() => getCitas()} type="date" ref={BusquedaInput} placeholder="Buscar Por Fecha" /> */}
						</div>
					</div>
				</div>

				{/* Tabla */}
				<div className="Listar_Table">
					<table className="tableListar" border="0">
						<thead className="tableListarHead">
							<tr className="tableListarRowHeader">
								<th className="tableListarCeldaHeader">Id</th>
								<th className="tableListarCeldaHeader">Fecha</th>
								<th className="tableListarCeldaHeader">Podologa</th>
								<th className="tableListarCeldaHeader">Total</th>
								<th className="tableListarCeldaHeader"></th>
								<th className="tableListarCeldaHeader"></th>
							</tr>
						</thead>
						<tbody className="tableListarBody">
							{ListaCitas.length === 0 && (
								<tr>
									<td colSpan={5} className="tableListarCelda_sinResultados">
										Sin resultados
									</td>
								</tr>
							)}
							{ListaCitas.map(
								(i, j) =>
									i.idPaciente === 0 && (
										<tr className="tableListarRow" key={j}>
											<td className="tableListarCelda">{i.idReceta}</td>
											<td className="tableListarCelda">{i.timestamp.split(' ')[0]}</td>
											<td className="tableListarCelda">{i.podologa}</td>
											<td className="tableListarCelda">{i.total}</td>
											<td className="tableListarCelda">
												<button className="btn-1" onClick={() => setDetalle(i.idReceta)}>
													Detalle
												</button>
											</td>
											<td className="tableListarCelda">
												<button className="btn-1" onClick={() => deleteVenta(i.idReceta)}>
													Borrar
												</button>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

const DetalleVenta = ({ idReceta, setView, setLoadState }) => {
	const [ListaMedicamentos, setListaMedicamentos] = useState([])

	const initData = async () => {
		setLoadState(true)
		const data = await getDataById('medicamento/ventas', idReceta)
		setListaMedicamentos(data)
		setLoadState(false)
	}

	useEffect(() => {
		initData()
	}, [])

	return (
		<div className="bkg-nPC detalleVentaListMed">
			<div className=" form formPop ">
				<button className="form__titleActionClose" onClick={() => setView(false)}>
					<IoCloseCircleSharp />
				</button>
				<div className="dpacMedList ">
					<h2 className="dpacMedTit">Medicamentos:</h2>
					{ListaMedicamentos.length > 0 ? (
						ListaMedicamentos.map((med, kIt) => (
							<div className="dpacMedListD" key={kIt}>
								<h3 className="depacMedLisItem">
									{med.medicamento} &nbsp;&nbsp;&nbsp; x {med.cant}
								</h3>
								<h3>{med.ct}</h3>
							</div>
						))
					) : (
						<h3>No se uso Medicamentos</h3>
					)}
				</div>
			</div>
		</div>
	)
}

export default ListarVentaMedicamentos
