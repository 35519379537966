import React, { useEffect, useState } from 'react'
import { HiOutlineEye } from 'react-icons/hi'
import TxtField from '../../../components/TxtField/TxtField'
import { RiFileAddLine } from 'react-icons/ri'
import { deleteData, searchData } from '../../../controllers/dataController'
import { getFecha } from '../../../controllers/fechaController'
import AddIngreso from './AddIngreso'
import { MdTextFields } from 'react-icons/md'
import EditIngreso from './EditIngreso'

const IngresosDiarios = ({ setRoute, setLoadState }) => {
	const [ListaIngresos, setListaIngresos] = useState([])
	const [Fecha, setFecha] = useState(getFecha())
	const [NewIngreso, setNewIngreso] = useState(false)
	const [EditarIngreso, setEditIngreso] = useState(false)

	const getIngresos = async () => {
		setLoadState(true)
		const data = await searchData('ingreso', 'timestamp', Fecha)
		console.log(data)
		setListaIngresos(data)
		setLoadState(false)
	}

	const deleteIngreso = async (idIngreso) => {
		if (idIngreso === '') return 0
		const opcion = window.confirm('¿Desea Borrar el Registro con el ID: ' + idIngreso + '? ')
		if (opcion === true) {
			setLoadState(true)
			await deleteData('ingreso', idIngreso)
			getIngresos()
		}
	}

	useEffect(() => {
		getIngresos()
	}, [Fecha, NewIngreso, EditarIngreso])

	return (
		<div className="page">
			{NewIngreso !== false && <AddIngreso setLoadeState={setLoadState} setView={setNewIngreso} />}
			{EditarIngreso !== false && (
				<EditIngreso item={EditarIngreso} setLoadeState={setLoadState} setView={setEditIngreso} />
			)}

			{/* Title Page */}
			<div className="page__pageTitle">
				<RiFileAddLine className="icon-links" /> <span className="pageTitle__span">Ingresos</span>
			</div>

			<div className="Listar">
				{/* Header */}
				<div className="Listar__Header">
					<h2 className="Listar__Header__Title">Tabla de Ingresos</h2>
					<button style={{ marginTop: '30px' }} className="btn-1" onClick={() => setNewIngreso(true)}>
						Añadir Registro
					</button>

					{/* ------------------ */}
					<div className="form__TextField totalIngresos">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>TOTAL DE INGRESOS:</label>
						</div>
						{ListaIngresos.length > 0 && (
							<span className="">
								S/{ListaIngresos.reduce((acumulador, it) => acumulador + parseFloat(it.costo), 0)}
							</span>
						)}
					</div>

					<div className="Listar__Header__Right" style={{}}>
						<div className="Listar__Header__InputSearch">
							<TxtField
								type="date"
								label={'Filtrar por Fecha'}
								name={'fecha'}
								defaultVal={Fecha}
								setVal={(name, val) => {
									setFecha(val)
								}}
							/>
						</div>
					</div>
				</div>

				{/* Tabla */}
				<div className="Listar_Table">
					<table className="tableListar" border="0">
						<thead className="tableListarHead">
							<tr className="tableListarRowHeader">
								<th className="tableListarCeldaHeader idCelda">Id</th>
								<th className="tableListarCeldaHeader">Fecha</th>
								<th className="tableListarCeldaHeader">Descripcion</th>
								<th className="tableListarCeldaHeader">Costo</th>
								<th className="tableListarCeldaHeader">Metodo de Pago</th>
								<th className="tableListarCeldaHeader">Podologa/Masajista</th>
								<th className="tableListarCeldaHeader"></th>
								<th className="tableListarCeldaHeader"></th>
							</tr>
						</thead>
						<tbody className="tableListarBody">
							{ListaIngresos.length === 0 && (
								<tr>
									<td colSpan={5} className="tableListarCelda_sinResultados">
										Sin resultados
									</td>
								</tr>
							)}
							{ListaIngresos.map((i, j) => (
								<tr className="tableListarRow" key={j}>
									<td className="tableListarCelda idCelda">{i.id}</td>
									<td className="tableListarCelda fechaCelda">{i.timestamp.split(' ')[0]}</td>
									<td className="tableListarCelda">{i.descripcion}</td>
									<td className="tableListarCelda">{i.costo}</td>
									<td className="tableListarCelda">{i.tipoPago}</td>
									<td className="tableListarCelda">{i.podologa}</td>
									<td>
										<button
											className="btn-1"
											onClick={() => {
												setEditIngreso(i)
											}}>
											Editar
										</button>
									</td>
									<td>
										<button
											style={{ background: '#fa4d4d', marginLeft: '10px' }}
											className="btn-1"
											onClick={() => deleteIngreso(i.id)}>
											Borrar
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default IngresosDiarios
