import React, { useEffect, useRef, useState } from 'react'
import './listWithCategories.css'
import { IoMdAddCircle } from 'react-icons/io'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight, MdTextFields } from 'react-icons/md'
import FormTextField from '../formTextField/FormTextField'
import { AiOutlineCloseCircle } from 'react-icons/ai'

const ListWithCategories = ({ Categories, Data, setD = () => {} }) => {
	const [CategoriesData, setCategories] = useState(Categories)
	const [DataD, setData] = useState(Data)
	const [Busq, setBusq] = useState('')
	const inputBusq = useRef('')
	useEffect(() => {}, [CategoriesData])

	const updateOpen = (k) => {
		const _data = CategoriesData
		_data[k].open === true ? (_data[k].open = false) : (_data[k].open = true)
		setCategories([..._data])
	}

	return (
		<div className="listWithCategories">
			<div className="listWithCategories__SearchInput">
				<div className="form__TextField">
					<div className="form__TextFieldLabel">
						<MdTextFields className="iconLabel" />
						<label>Buscar Medicamento</label>
					</div>
					<input ref={inputBusq} placeholder={'Buscar'} autoComplete="off" onChange={(e) => setBusq(e.target.value)} />
				</div>

				<AiOutlineCloseCircle
					onClick={() => {
						setBusq('')
						inputBusq.current.value = ''
					}}
					className="clear_input"
				/>

				<div className="">
					{Busq !== '' &&
						DataD.length > 0 &&
						DataD.map(
							(q, w) =>
								q.medicamento.toLowerCase().startsWith(Busq.toLowerCase()) && (
									<div key={w} className="listWithCategories__itemListProduct__item">
										<span className="itemListProduct__item__text">{q.medicamento}</span>
										<span className="itemListProduct__item__text">Stock: {q.stock != null ? q.stock : '0'}</span>
										<span className="itemListProduct__item__text">{q.cuv != null ? 'S/' + q.cuv : '-'}</span>
										{q.stock > 0 && (
											<span className="itemListProduct__item__action" onClick={() => setD(q)}>
												<IoMdAddCircle />
											</span>
										)}
									</div>
								)
						)}
				</div>
			</div>
			{CategoriesData.length > 0 &&
				CategoriesData.map((i, k) => (
					<div className={i.open === true ? 'listWithCategories__item openCat ' : 'listWithCategories__item'} key={k}>
						<div className="listWithCategories__itemHeader" onClick={() => updateOpen(k)}>
							{i.open ? (
								<MdOutlineKeyboardArrowDown className="listWithCategories__itemHeaderIcon" />
							) : (
								<MdOutlineKeyboardArrowRight className="listWithCategories__itemHeaderIcon" />
							)}{' '}
							<span className="listWithCategories__itemHeaderText">{i.categoria}</span>
						</div>
						<div className="listWithCategories__itemListProduct">
							{DataD.length > 0 ? (
								DataD.map(
									(q, w) =>
										q.idCategoria === i.idCategoria && (
											<div key={w} className="listWithCategories__itemListProduct__item">
												<span className="itemListProduct__item__text">{q.medicamento}</span>
												<span className="itemListProduct__item__text">Stock: {q.stock != null ? q.stock : '0'}</span>
												<span className="itemListProduct__item__text">{q.cuv != null ? 'S/' + q.cuv : '-'}</span>
												{q.stock > 0 && (
													<span className="itemListProduct__item__action" onClick={() => setD(q)}>
														<IoMdAddCircle />
													</span>
												)}
											</div>
										)
								)
							) : (
								<div className="listWithCategories__itemListProduct__empty">No ha resultados</div>
							)}
						</div>
					</div>
				))}
		</div>
	)
}

export default ListWithCategories
