import React, { useEffect, useState } from 'react'
import FormTextField from '../../../../components/formTextField/FormTextField'
import DatosCita from './nc-section-3-content/nc-section-3-datos-cita'
import MedicamentosCart from './nc-section-3-content/nc-section-3-medicamentos'
import PaquetesCart from './nc-section-3-content/nc-section-3-paquetes'
import HistorialPaciente from '../../../paciente/views/historialPaciente/historialPaciente'

const NcSection3 = ({ setSection, Paciente, setDatosCita, Cita, setLoadState }) => {
	const [Nav, setNav] = useState(1)
	const [ViewHistorial, setViewHistorial] = useState(false)

	const initData = () => {
		if (Paciente.paquetes !== undefined) {
			if (Paciente.paquetes.length > 0) {
				var _cita = Cita
				_cita.paquetes = Paciente.paquetes
				setDatosCita(_cita)
			}
		}
	}

	useEffect(() => {
		initData()
	}, [])

	return (
		<div className="nc-section-3">
			{ViewHistorial && <HistorialPaciente item={Paciente} setView={setViewHistorial} setLoadState={setLoadState} />}

			{/* Data Paciente */}
			<div className="ncs3__PacienteData">
				<FormTextField
					label={'Paciente:'}
					name={'paciente'}
					setVal={() => {}}
					value={Paciente.nombre + ' ' + Paciente.apellidos}
					readOnly={true}
				/>
				<button className="btn-1" onClick={() => setViewHistorial(true)}>
					Ver Historial
				</button>
			</div>

			{/* Sections */}
			<div className="sectionsBox">
				<div className="sectionsBox__sectionsList">
					<span
						className={Nav === 1 ? 'sectionsList__ListItem active' : 'sectionsList__ListItem'}
						onClick={() => setNav(1)}>
						Datos de la Cita
					</span>
					<span
						className={Nav === 2 ? 'sectionsList__ListItem active' : 'sectionsList__ListItem'}
						onClick={() => setNav(2)}>
						Medicamentos ({Cita.medicamentos.length})
					</span>
					<span
						className={Nav === 3 ? 'sectionsList__ListItem active' : 'sectionsList__ListItem'}
						onClick={() => setNav(3)}
						// onClick={() => alert('La seccion paquetes estara habilitida el dia viernes 30 de Junio')}
						>
						Paquetes ({Cita.paquetes.length})
					</span>
				</div>
				{Nav === 1 && <DatosCita Cita={Cita} setDatosCita={setDatosCita} setLoadState={setLoadState} />}
				{Nav === 2 && <MedicamentosCart Cita={Cita} setDatosCita={setDatosCita} setLoadState={setLoadState} />}
				{Nav === 3 && (
					<PaquetesCart Cita={Cita} setDatosCita={setDatosCita} setLoadState={setLoadState} Paciente={Paciente} />
				)}
			</div>
		</div>
	)
}

export default NcSection3
