import React from 'react'
import './formTitle.css'
import { AiFillPlusCircle } from 'react-icons/ai'
import { IoCloseCircleSharp } from 'react-icons/io5'

const FormTitle = ({ title, setView, close = () => {} }) => {
	return (
		<div className="form__title">
			<AiFillPlusCircle className="form__titleIcon" />
			<span className="form__titleText">{title}</span>
			<button
				className="form__titleActionClose"
				onClick={() => {
					close()
					setView(false)
				}}>
				<IoCloseCircleSharp />
			</button>
		</div>
	)
}

export default FormTitle
