import React, { useEffect, useState } from 'react'
import ListWithCategories from '../../../../../components/listWithCategories/listWithCategories'
import { getData } from '../../../../../controllers/dataController'
import ListCart from '../../../../../components/listCart/listCart'

const MedicamentosCartEditar = ({ Cita, setDatosCita, setLoadState }) => {
	const [Categorias, setCategorias] = useState([])
	const [Medicamentos, setMedicamentos] = useState([])

	const updateTxtFields = (name, val) => {
		setDatosCita({ ...Cita, [name]: val })
	}

	const updateCart = (item, cant = 1) => {
		setLoadState(true)
		var _cart = []

		if (item === 'delete') {
			_cart = Cita.medicamentos
			_cart.splice(cant, 1)
			updateTxtFields('medicamentos', _cart)
			return setLoadState(false)
		}

		if (cant <= 0) {
			alert('La cantidad tiene que ser mayor a "0"')
			return setLoadState(false)
		}

		if (Cita.medicamentos.lenght === 0) {
			item.cant = cant
			_cart.push(item)
		} else {
			_cart = Cita.medicamentos
			const elementIndex = _cart.findIndex((obj) => obj.idMedicamento === item.idMedicamento)
			if (elementIndex !== -1) {
				// Update
				_cart[elementIndex].cant = cant
			} else {
				// Add
				item.cant = cant
				_cart.push(item)
			}
		}
		// Update in DataCita
		updateTxtFields('medicamentos', _cart)
		setLoadState(false)
	}

	const initData = async () => {
		setLoadState(true)
		// Get categorias
		const dataCat = await getData('categoria')
		setCategorias(dataCat)
		// Get Medicamentos
		const dataMed = await getData('medicamento')
		setMedicamentos(dataMed)
		setLoadState(false)
	}

	useEffect(() => {
		initData()
	}, [Cita])

	return (
		<div className="sectionsBox__Body sectionBox__Medicamentos">
			{/* List with categories */}
			{Medicamentos.length > 0 && <ListWithCategories Categories={Categorias} Data={Medicamentos} setD={updateCart} />}

			{/* List Cart */}
			<ListCart Data={Cita.medicamentos} setD={updateCart} />

			{/* Detalle Cart */}
			<div className="detailCart">
				<div className="total">
					<div className="total__text">
						<label className="total_label">Total</label>
					</div>
					<input
						className="total__show"
						value={+Cita.medicamentos.reduce((acumulador, it) => acumulador + it.cant * it.cuv, 0)}
						readOnly={true}
					/>
				</div>
			</div>
		</div>
	)
}

export default MedicamentosCartEditar
