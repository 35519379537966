import React, { useEffect, useRef, useState } from 'react'
import { MdTextFields } from 'react-icons/md'
import './cbxwIn.css'

const CbxFieldWithInput = ({ placeholder = '', value = '', label, setVal, name, options = [], map_text }) => {
	const [Open, setOpen] = useState(false)
	const [data, setData] = useState(options)
	const valueData = useRef(null)

	const onChnge = (e) => {
		if (e.target.value === '') {
			return setData(options)
		}

		setVal(name, e.target.value)
		const _options = options.filter((i) => i[map_text].includes(e.target.value))
		setData(_options)
	}

	const onClickOption = (_txt) => {
		valueData.current.value = _txt
		setVal(name, _txt)
	}

	useEffect(() => {
		setData(options)
	}, [])

	return (
		<div className="form__TextField">
			<div className="form__TextFieldLabel">
				<MdTextFields className="iconLabel" />
				<label>{label}</label>
			</div>
			<input
				autoComplete="off"
				placeholder={placeholder}
				defaultValue={value}
				name={name}
				onChange={(e) => onChnge(e)}
				onClick={() => setOpen(true)}
				onBlur={() =>
					setTimeout(() => {
						setOpen(false)
					}, [200])
				}
				ref={valueData}
			/>
			{data.length > 0 && (
				<div className={Open ? 'selectFieldCustom openSelect' : 'selectFieldCustom'}>
					{data.map((i, k) => (
						<span key={k} className="selectFieldCustom__Item" onClick={() => onClickOption(i[map_text])}>
							{i[map_text]}
						</span>
					))}
				</div>
			)}
		</div>
	)
}

export default CbxFieldWithInput
