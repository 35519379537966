import React from 'react'
import './loading.css'

const Loading = () => {
	return (
		<div className="loading">
			<div className="loader"></div>
		</div>
	)
}

export default Loading
