import React, { useEffect, useState } from 'react'
import FormTitle from '../../../components/formTitle/FormTitle'
import FormTextField from '../../../components/formTextField/FormTextField'
import CbxFieldMap from '../../../components/CbxFieldMap/CbxFieldMap'
import { getData, setData, updateData } from '../../../controllers/dataController'
import { getFecha } from '../../../controllers/fechaController'
import CbxField from '../../../components/CbxField/CbxField'

const EditIngreso = ({ setLoadeState, setView, item }) => {
	const [PodologaData, setPodologaData] = useState([])
	const [DataIngreso, setDataIngreso] = useState({ ...item })

	const UpdateIngreso = async () => {
		if (DataIngreso.timestamp === '') return alert('Seleccione Fecha')
		if (DataIngreso.idPodologa === '') return alert('Seleccione Podologa/Masajista')
		if (DataIngreso.descripcion === '') return alert('La descripcion no debe ser vacio!')
		if (DataIngreso.costo <= 0) return alert('El costo no debe ser vacio!')
		console.log(DataIngreso)
		setLoadeState(true)

		await updateData('ingreso', DataIngreso, item.id)

		setView(false)
		setLoadeState(false)
	}

	const setVal = (name, val) => setDataIngreso({ ...DataIngreso, [name]: val })

	const initData = async () => {
		setLoadeState(true)
		// Data Select
		const dataPod = await getData('podologa')
		console.log(dataPod)
		setPodologaData(dataPod)
		setLoadeState(false)
	}

	useEffect(() => {
		initData()
	}, [])

	return (
		<div className="formPopBkg">
			<div className="form formPop">
				{/* Title */}
				<FormTitle setView={setView} title={'Editar Ingreso'} />

				<div className="formBody">
					{/* Fecha */}
					<FormTextField
						label={'Fecha'}
						name={'timestamp'}
						setVal={setVal}
						type="date"
						value={item.timestamp.split(' ')[0]}
					/>

					{/*  Podologa select */}
					{PodologaData.length > 0 && (
						<CbxFieldMap
							label={'Podologa'}
							name={'idPodologa'}
							map_text={['nombre', 'apellido']}
							map_value={'idPodologa'}
							setVal={setVal}
							options={PodologaData}
							defValue={item.idPodologa}
						/>
					)}

					{/* DEscripcion */}
					<FormTextField label={'Descripcion'} name={'descripcion'} setVal={setVal} value={item.descripcion} />

					{/*  Tipo Pago select */}
					<div className="txtField">
						<label className="txtField__label">Tipo de Pago:</label>
						<select defaultValue={item.tipoPago} className="selectField" onChange={(i) => setVal('tipoPago', i.currentTarget.value)}>
							<option value={''}>---Seleccione---</option>
							<option  value="Efectivo">Efectivo</option>
							<option value="Yape">Yape</option>
							<option value="Transferencia">Transferencia</option>
							<option value="POS">POS</option>
						</select>
					</div>

					{/* Costo */}
					<FormTextField label={'Costo'} name={'costo'} type="number" setVal={setVal} value={item.costo} />

					{/* Action Buttons */}
					<div className="form__groupBtns">
						<button
							className="form__btnCancel"
							onClick={() => {
								setView(false)
							}}>
							Cancelar
						</button>
						<button className="form__btnSuccess" onClick={() => UpdateIngreso()}>
							Confirmar
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EditIngreso
