import React, { useEffect, useState } from 'react'
import { getData } from '../../../../../controllers/dataController'
import PieSup from '../../../../../components/pie/pie-sup/PieSup'
import PieInf from '../../../../../components/pie/pie-inf/PieInf'
import CbxFieldMap from '../../../../../components/CbxFieldMap/CbxFieldMap'
import CbxFieldWithInput from '../../../../../components/CbxFieldWithInput/CbxFieldWithInput'
import FormTextField from '../../../../../components/formTextField/FormTextField'
import FormTextArea from '../../../../../components/formTextArea/formTextArea'

const DatosCita = ({ Cita, setDatosCita, setLoadState }) => {
	const [PodologaData, setPodologaData] = useState([])
	const [DiagnosticoData, setDiagnosticoData] = useState([])
	const [TratamientoData, setTratamientoData] = useState([])

	const [PieSupD, setPieSup] = useState({
		pi_1: false,
		pi_2: false,
		pi_3: false,
		pi_4: false,
		pi_5: false,
		pi_6: false,
		pi_7: false,
		pi_8: false,
		pi_9: false,
		pi_10: false,
		pd_1: false,
		pd_2: false,
		pd_3: false,
		pd_4: false,
		pd_5: false,
		pd_6: false,
		pd_7: false,
		pd_8: false,
		pd_9: false,
		pd_10: false
	})
	const [PieInfD, setPieInf] = useState({
		zi_1: false,
		zi_2: false,
		zi_3: false,
		zi_4: false,
		zi_5: false,
		zi_6: false,
		zi_7: false,
		zi_8: false,
		di_1: false,
		di_2: false,
		di_3: false,
		di_4: false,
		di_5: false,
		di_6: false,
		di_7: false,
		di_8: false
	})

	const updateTxtFields = (name, val) => {
		setDatosCita({ ...Cita, [name]: val })
	}

	const initData = async () => {
		setLoadState(true)
		// --- Data pie
		var _cita = Cita
		if (Cita.inferior === null) {
			_cita.inferior = PieInfD
			setDatosCita(_cita)
		} else {
			setPieInf(_cita.inferior)
		}
		if (Cita.posterior === null) {
			_cita.posterior = PieSupD
			setDatosCita(_cita)
		} else {
			setPieSup(_cita.posterior)
		}

		setDatosCita(_cita)
		// --

		// Data Select
		const dataPod = await getData('podologa')
		setPodologaData(dataPod)
		const dataDiag = await getData('diagnostico')
		setDiagnosticoData(dataDiag)
		setLoadState(false)
		const dataTrat = await getData('tratamiento')
		setTratamientoData(dataTrat)
		setLoadState(false)
	}

	useEffect(() => {
		initData()
	}, [])

	const setPieInfCtrl = (data) => {
		setLoadState(true)
		var _cita = Cita
		_cita.inferior = data
		setPieInf(data)
		setDatosCita(_cita)
		setLoadState(false)
	}
	const setPieSupCtrl = (data) => {
		setLoadState(true)
		var _cita = Cita
		_cita.posterior = data
		setPieSup(data)
		setDatosCita(_cita)
		setLoadState(false)
	}

	return (
		<div className="sectionsBox__Body">
			{/* Estado Pies */}
			<div className="estadoPies">
				<PieSup Data={PieSupD} setData={setPieSupCtrl} />
				<PieInf Data={PieInfD} setData={setPieInfCtrl} />
			</div>

			{/* Section data Cita */}
			<div className="sectionDataCita">
				{/* Fecha */}
				<FormTextField value={Cita.fecha} label={'Fecha'} name={'fecha'} type="date" setVal={updateTxtFields} />

				{/*  Podologa select */}
				{PodologaData.length > 0 && (
					<CbxFieldMap
						defValue={Cita.idPodologa}
						label={'Podologa'}
						name={'idPodologa'}
						map_text={['nombre', 'apellido']}
						map_value={'idPodologa'}
						setVal={updateTxtFields}
						options={PodologaData}
					/>
				)}

				{/* Diagnostico */}
				{DiagnosticoData.length > 0 && (
					<CbxFieldWithInput
						placeholder="Escribe Aquí"
						value={Cita.diagnostico}
						label={'Diagnostico'}
						name={'diagnostico'}
						setVal={updateTxtFields}
						map_text={['diagnostico']}
						options={DiagnosticoData}
					/>
				)}

				{/* Tratamiento */}
				{TratamientoData.length > 0 && (
					<CbxFieldWithInput
						placeholder="Escribe Aquí"
						value={Cita.tratamiento}
						label={'Tratamiento'}
						name={'tratamiento'}
						setVal={updateTxtFields}
						map_text={['tratamiento']}
						options={TratamientoData}
					/>
				)}

				{/* Precio del Tratamiento */}
				<FormTextField
					value={Cita.precio_trat}
					label={'Precio del tratamiento'}
					name={'precio_trat'}
					type="number"
					setVal={updateTxtFields}
					placeholder="Escribe Aquí"
				/>

				{/* Observacion */}
				<FormTextArea
					label={'Observacion'}
					name={'obs'}
					setVal={updateTxtFields}
					value={Cita.obs}
					placeholder="Escribe Aquí"
				/>
			</div>
		</div>
	)
}

export default DatosCita
