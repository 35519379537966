import React from 'react'
import './TxtField.css'

const TxtField = ({ type = 'text', label, setVal = () => {}, name, defaultVal = '' }) => {
	return (
		<div className="txtField">
			<label className="txtField__label">{label}</label>
			<input
				defaultValue={defaultVal}
				autoComplete="off"
				type={type}
				onChange={(i) => setVal(name, i.currentTarget.value)}
			/>
		</div>
	)
}

export default TxtField
