import React, { useEffect, useState } from 'react'
import PieSup from '../../../../components/pie/pie-sup/PieSup'
import PieInf from '../../../../components/pie/pie-inf/PieInf'
import './historialPaciente.css'
import { getFree } from '../../../../controllers/dataController'
import { IoCloseCircleSharp } from 'react-icons/io5'

const HistorialPaciente = ({ item, setView, setLoadState }) => {
	const [Historial, setHistorial] = useState([])

	const initData = async () => {
		setLoadState(true)
		const data = await getFree('paciente/historial/' + item.idPaciente)
		console.log(data)
		setHistorial(data)
		setLoadState(false)
	}

	useEffect(() => {
		initData()
	}, [])

	return (
		<div className="bkg-Dp">
			<div className="dPac">
				<div className="dPacClose" onClick={() => setView(false)}>
					<IoCloseCircleSharp />
				</div>
				<h2 className="dPacTitle">
					{item.nombre} {item.apellidos}
				</h2>
				<div className="dPacBody">
					{Historial.length === 0 ? (
						<div className="dPacSH">No tiene historial</div>
					) : (
						Historial.map((i, k) => (
							<div className="dpacItem" key={k}>
								<div className="dpacItTxt dpacFech">
									<label>Fecha:</label>
									<input type="date" value={i.timestamp.split(' ')[0]} readOnly />
								</div>
								<div className="dpacItTxt dpacPod">
									<label>Podologa:</label>
									<input type="text" value={i.podologa} readOnly />
								</div>
								<div className="dpacItTxt dpacDiag">
									<label>Diagnostico: </label>
									<input type="text" value={i.diagnostico} readOnly />
								</div>
								<div className="dpacItTxt dpacTrat">
									<label>Tratamiento: </label>
									<input type="text" value={i.tratamiento} readOnly />
								</div>
								<div className="dpacItTxt dpacPreTr">
									<label>Precio del Tratamiento: </label>
									<input type="number" value={i.precio_trat} readOnly />
								</div>
								<div className="dpacItTxt dpacPreTr dPacTxtAreaD">
									<label>Observación:</label>
									<textarea value={i.obs} readOnly></textarea>
								</div>
								<div className="dpacItTxt dpacPrecio">
									<label>Total: </label>
									<span className="precioTratTotal">{i.precio}</span>
								</div>

								<div className="dpacMedList">
									<h2 className="dpacMedTit">Medicamentos:</h2>
									{i.idReceta != null ? (
										i.medicamentos.map((med, kIt) => (
											<div className="dpacMedListD" key={kIt}>
												<h3 className="depacMedLisItem">
													{med.medicamento} &nbsp;&nbsp;&nbsp; x {med.cant}
												</h3>
												<h3>{med.ct}</h3>
											</div>
										))
									) : (
										<h3>No se uso Medicamentos</h3>
									)}
								</div>

								<div className="dpacMedList">
									<h2 className="dpacMedTit">Paquetes:</h2>
									<div className="paquetesCartSend">
										{i.paquetes.length > 0 ? (
											i.paquetes.map((med, kIt) => (
												<div className="paquetesCartSend__Item" key={kIt}>
													<div className="paquetesCartSend__ItemSeTitle">{med.paquete}</div>
													<span className="paquetesCartSend__sesionBody">Sesión: {med.sesion}</span>
													<span className="paquetesCartSend__sesionBody">Abono: {med.abono}</span>
													{/* <span className="paquetesCartSend__sesionBody">Deuda: {med.deuda}</span> */}
												</div>
											))
										) : (
											<h3>No se uso Paquetes</h3>
										)}
									</div>
								</div>

								<div className="estadoPies">
									<PieSup Data={JSON.parse(i.posterior)} setData={() => {}} />
									<PieInf Data={JSON.parse(i.inferior)} setData={() => {}} />
								</div>
							</div>
						))
					)}
				</div>
			</div>
		</div>
	)
}

export default HistorialPaciente
