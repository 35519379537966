import React, { useEffect, useState } from 'react'
import FormTitle from '../../../components/formTitle/FormTitle'
import FormTextField from '../../../components/formTextField/FormTextField'
import CbxFieldMap from '../../../components/CbxFieldMap/CbxFieldMap'
import { getData, setData, updateData } from '../../../controllers/dataController'
import { getFecha } from '../../../controllers/fechaController'
import CbxField from '../../../components/CbxField/CbxField'

const EditAgenda = ({ setLoadeState, setView, item }) => {
	const [PacienteData, setPacienteData] = useState([])
	const [DataAgenda, setDataAgenda] = useState({ ...item })

	const UpdateAgenda = async () => {
		if (DataAgenda.idPaciente === '') return alert('Seleccione Paciente')
		if (DataAgenda.celular === '') return alert('Ingrese el celular')
		if (DataAgenda.fecha === '') return alert('Seleccione Fecha')
		console.log(DataAgenda)
		setLoadeState(true)

		await updateData('agenda', DataAgenda, item.id)

		setView(false)
		setLoadeState(false)
	}

	const setVal = (name, val) => setDataAgenda({ ...DataAgenda, [name]: val })

	const initData = async () => {
		setLoadeState(true)
		// Data Select
		const dataPod = await getData('paciente')
		console.log(dataPod)
		setPacienteData(dataPod)
		setLoadeState(false)
	}

	useEffect(() => {
		initData()
	}, [])

	return (
		<div className="formPopBkg">
			<div className="form formPop">
				{/* Title */}
				<FormTitle setView={setView} title={'Editar Agenda'} />

				<div className="formBody">
					{/*  Paciente select */}
					{PacienteData.length > 0 && (
						<CbxFieldMap
							defValue={DataAgenda.idPaciente}
							label={'Paciente'}
							name={'idPaciente'}
							map_text={['nombre', 'apellidos']}
							map_value={'idPaciente'}
							setVal={setVal}
							options={PacienteData}
						/>
					)}

					{/* Celular */}
					<FormTextField value={DataAgenda.celular} label={'Celular'} name={'celular'} type="number" setVal={setVal} />

					{/* Fecha */}
					<FormTextField
						value={DataAgenda.fecha.split(' ')[0]}
						label={'Fecha de la cita'}
						name={'fecha'}
						setVal={setVal}
						type="date"
					/>

					{/* Action Buttons */}
					<div className="form__groupBtns">
						<button
							className="form__btnCancel"
							onClick={() => {
								setView(false)
							}}>
							Cancelar
						</button>
						<button className="form__btnSuccess" onClick={() => UpdateAgenda()}>
							Confirmar
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EditAgenda
