import React from 'react'
import { MdTextFields } from 'react-icons/md'

const FormTextArea = ({ placeholder = '', label, setVal = () => {}, name, value = '', readOnly = false }) => {
	return (
		<div className="form__TextField form__textArea">
			<div className="form__TextFieldLabel">
				<MdTextFields className="iconLabel" />
				<label>{label}</label>
			</div>
			<textarea
				placeholder={placeholder}
				defaultValue={value}
				readOnly={readOnly}
				name={name}
				onChange={(e) => setVal(name, e.target.value)}></textarea>
		</div>
	)
}

export default FormTextArea
