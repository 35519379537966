import React, { useEffect, useState } from 'react'
import { IoAddOutline } from 'react-icons/io5'
import { RiShoppingBag3Line } from 'react-icons/ri'
import FormTextField from '../../../../../components/formTextField/FormTextField'
import { AiFillCloseCircle } from 'react-icons/ai'
import { getData } from '../../../../../controllers/dataController'

const PaquetesCart = ({ Cita, setDatosCita, setLoadState, Paciente }) => {
	const [CartPaquetes, setCartPaquetes] = useState([...Cita.paquetes])
	const [Paquetes, setPaquetes] = useState([])

	const updateTxtFields = (name, val) => {
		setDatosCita({ ...Cita, [name]: val })
	}

	const getPaquetes = async () => {
		setLoadState(true)
		const _dataPaquetes = await getData('paquete')
		setPaquetes(_dataPaquetes)
		setLoadState(false)
	}

	const updateUse = (index, val) => {
		if (CartPaquetes.length > 0) {
			setLoadState(true)
			var _cart = CartPaquetes
			_cart[index].use = val
			if (val === false) {
				_cart[index].abono = 0
			}
			setCartPaquetes(_cart)
			updateTxtFields('paquetes', _cart)
			setLoadState(false)
		}
	}

	const updateCartPaquet = (item, cant = 0) => {
		setLoadState(true)
		var _cart = []

		if (item === 'delete') {
			_cart = CartPaquetes
			_cart.splice(cant, 1)
			setCartPaquetes(_cart)
			updateTxtFields('paquetes', _cart)
			return setLoadState(false)
		}

		if (CartPaquetes.length === 0) {
			item.abono = 0
			_cart.push(item)
			setCartPaquetes(_cart)
			updateTxtFields('paquetes', _cart)
		} else {
			_cart = CartPaquetes
			const elementIndex = _cart.findIndex((obj) => obj.idPaquete === item.idPaquete)
			if (elementIndex !== -1) {
				if (cant !== 0) {
					if (cant === '') cant = 0
					// Update
					_cart[elementIndex].abono = cant
					setCartPaquetes(_cart)
					updateTxtFields('paquetes', _cart)
				} else {
					alert('El paquete ya esta en uso')
				}
			} else {
				// Add
				item.abono = 0
				_cart.push(item)
				setCartPaquetes(_cart)
				updateTxtFields('paquetes', _cart)
			}
		}
		// Update in DataCita
		updateTxtFields('paquetes', _cart)
		setLoadState(false)
	}

	useEffect(() => {
		getPaquetes()
	}, [])

	return (
		<div className="sectionsBox__Body section__Paquetes">
			{/* Paquetes suscritos */}
			<div className="paquetesSuscritos">
				<div className="paquetesSuscritos__title">
					<RiShoppingBag3Line className="paquetesSuscritos__titleIcon" />
					<h2 className="paquetesSuscritos__titleText">Paquetes Subscritos</h2>
				</div>
				<div className="paquetesCartSend">
					{/* Item */}
					{CartPaquetes.length > 0 ? (
						CartPaquetes.map((i, k) =>
							i.use === undefined ? (
								<div className="paquetesCartSend__Item" key={k}>
									<AiFillCloseCircle
										className="paquetesCartSend__close"
										onClick={() => updateCartPaquet('delete', k)}
									/>
									<div className="paquetesCartSend__Item__Section1">
										<div className="paquetesCartSend__ItemSeTitle">{i.paquete}</div>
										<span className="paquetesCartSend__sesionBody">Costo Total: {i.costo}</span>
										<span className="paquetesCartSend__sesionBody">Sesión correspondiente: 1</span>
										<span className="paquetesCartSend__sesionBody">
											Cuota correspondiente: {i.cuotas.split(';')[0]}
										</span>
										{/* <span className="paquetesCartSend__sesionBody">
											Sesiones Restantes: {i.cuotas.split(';').lenght}
										</span> */}
									</div>
									<div className="paquetesCartSend__Item__Section2" style={{ justifyContent: 'end !important' }}>
										<FormTextField
											label={'Abono'}
											name={'abono'}
											placeholder="0.00"
											value={i.abono}
											type="number"
											setVal={(name, value) => updateCartPaquet(i, value)}
										/>
									</div>
								</div>
							) : (
								<div className="paquetesCartSend__Item" key={k}>
									<div className="paquetesCartSend__Item__Section1">
										<div className="paquetesCartSend__ItemSeTitle">{i.paquete}</div>
										<span className="paquetesCartSend__sesionBody">Sesión correspondiente: {i.sesion_actual}</span>
										{i.costo - i.pagado !== 0 && (
											<span className="paquetesCartSend__sesionBody">
												Cuota correspondiente: {i.cuotas.split(';')[i.sesion_actual - 1]}
											</span>
										)}
										<span className="paquetesCartSend__sesionBody">Deuda Total: {i.costo - i.pagado}</span>
										<span className="paquetesCartSend__sesionBody">
											Total de Sesiones: {i.sesiones}
										</span>
										<span className="paquetesCartSend__sesionBody">
											Sesiones Restantes: {i.sesiones - i.sesion_actual}
										</span>
									</div>
									<div className="paquetesCartSend__Item__Section2">
										<button className="btn-line" style={{ cursor: 'pointer' }} onClick={() => updateUse(k, !i.use)}>
											{i.use ? 'No Usar' : 'Usar'}
										</button>
										{i.use && i.costo - i.pagado !== 0 && (
											<FormTextField
												label={'Abono'}
												name={'abono'}
												placeholder="0.00"
												setVal={(name, value) => updateCartPaquet(i, value)}
												value={i.abono}
											/>
										)}
									</div>
								</div>
							)
						)
					) : (
						<div className="emptyCart">No tiene suscripciones</div>
					)}
				</div>
			</div>

			{/* Add Paquets */}
			<div className="paquetesSuscritos">
				<div className="paquetesSuscritos__title">
					<RiShoppingBag3Line className="paquetesSuscritos__titleIcon" />
					<h2 className="paquetesSuscritos__titleText">Paquetes Subscritos</h2>
				</div>
				<div className="paquetesCart">
					{Paquetes.length > 0 &&
						Paquetes.map((i, k) => (
							<div className="paquetesCart__Item" key={k}>
								<span className="paquetesCart__ItemName">
									{i.idPaquete} - {i.paquete}
								</span>
								<span className="paquetesCart__ItemSesiones">Sesiones: {i.sesiones}</span>
								<span className="paquetesCart__ItemPrecio">Precio: S/. {i.costo}</span>
								<IoAddOutline className="paquetesCart__ItemIcon" onClick={() => updateCartPaquet(i)} />
							</div>
						))}
				</div>
			</div>
		</div>
	)
}

export default PaquetesCart
