import React, { useEffect, useState } from 'react'
import './nuevaCita.css'
import { RiFileAddLine } from 'react-icons/ri'
import { AiFillBackward } from 'react-icons/ai'
import registrarCita from '../../controllers/registrarCita'
import { getFecha } from '../../../../controllers/fechaController'
import NcSection3Editar from './nc-section-3-editar'
import { getFree } from '../../../../controllers/dataController'
import ActualizarCita from '../../controllers/actualizarCita'

const EditarCita = ({ setRoute, setLoadState, idCita }) => {
	const [Paciente, setPaciente] = useState(false)

	const [Cita, setDatosCita] = useState({})

	const initData = async () => {
		setLoadState(true)
		const data = await getFree('cita/detalle/' + idCita)
		console.log(data)
		data[0].inferior = JSON.parse(data[0].inferior)
		data[0].posterior = JSON.parse(data[0].posterior)
		setDatosCita(data[0])
		setTimeout(() => {
			setLoadState(false)
		}, [600])
	}

	useEffect(() => {
		initData()
	}, [])

	return (
		<div className="page nuevaCita">
			{/* Title Page */}
			<div className="page__pageTitle">
				<RiFileAddLine className="icon-links" /> <span className="pageTitle__span">Editar Cita</span>
			</div>
			{Cita.idPaciente !== undefined && (
				<NcSection3Editar Paciente={Paciente} Cita={Cita} setDatosCita={setDatosCita} setLoadState={setLoadState} />
			)}

			{/* Actions Page */}
			<div className="actionsPage">
				<div className="DatosCita__Total flex row">
					<label className="DatosCitaTotal__label">TOTAL</label>
					<div className="DatosCitaTotal__DivInput ">
						<span className="DatosCitaTotal__prec">S/</span>
						{Cita.precio !== undefined && (
							<input
								value={
									parseFloat(Cita.precio_trat === '' ? 0 : Cita.precio_trat) +
									parseFloat(Cita.medicamentos.reduce((acumulador, it) => acumulador + it.cant * it.cuv, 0)) +
									parseFloat(Cita.paquetes.reduce((acumulador, it) => acumulador + parseFloat(it.abono), 0))
								}
								readOnly
								className="DatosCitaTotal__TotalIn"
							/>
						)}
					</div>
				</div>

				<button className="btn-1" onClick={() => ActualizarCita(Cita, setRoute, setLoadState)}>
					Actualizar
				</button>
			</div>
		</div>
	)
}

export default EditarCita
