import { setData, updateData } from '../../../controllers/dataController'
import { getTime } from '../../../controllers/fechaController'

const ActualizarCita = async (cita, setRoute, setLoadState) => {
	setLoadState(true)

	// Token empty
	if (!localStorage.getItem('token')) {
		alert('Vuelve a iniciar Sesion')
		window.location.reload()
	}

	// Validate
	if (cita.idPaciente === null) {
		setLoadState(false)
		return alert('El campo "Paciente" esta vacio')
	}
	if (cita.diagnostico === null) {
		setLoadState(false)
		return alert('El campo "Diagnostico" esta vacio')
	}
	if (cita.tratamiento === null) {
		setLoadState(false)
		return alert('El campo "Tratamiento" esta vacio')
	}

	if (cita.idPodologa === 0) {
		setLoadState(false)
		return alert('El campo "Podologa" esta vacio')
	}

	// Add Token
	cita.token = localStorage.getItem('token')
	// Add Precio
	cita.precio =
		parseFloat(cita.precio_trat) +
		parseFloat(cita.medicamentos.reduce((acumulador, it) => acumulador + it.cant * it.cuv, 0)) +
		parseFloat(cita.paquetes.reduce((acumulador, it) => acumulador + parseFloat(it.abono), 0))
	// Add timestamp
	cita.timestamp = cita.timestamp.split(' ')[0] + ' ' + getTime()
	// Json to String
	// cita.inferior = JSON.stringify(cita.inferior)
	// cita.posterior = JSON.stringify(cita.posterior)

	await updateData('cita', cita, cita.idCita)
	setLoadState(false)

	setRoute('ver-citas')
}

export default ActualizarCita
