import React from 'react'
import './PieInf.css'
import infIzq from '../img/inferior/infierior-izqui.png'
import infDer from '../img/inferior/inferior-derec.png'

import di_1 from '../img/inferior/di-1.png'
import din_1 from '../img/inferior/din-1.png'
import di_2 from '../img/inferior/di-2.png'
import din_2 from '../img/inferior/din-2.png'
import di_3 from '../img/inferior/di-3.png'
import din_3 from '../img/inferior/din-3.png'
import di_4 from '../img/inferior/di-4.png'
import din_4 from '../img/inferior/din-4.png'
import di_5 from '../img/inferior/di-5.png'
import din_5 from '../img/inferior/din-5.png'
import di_6 from '../img/inferior/di-6.png'
import din_6 from '../img/inferior/din-6.png'
import di_7 from '../img/inferior/di-7.png'
import din_7 from '../img/inferior/din-7.png'
import di_8 from '../img/inferior/di-8.png'
import din_8 from '../img/inferior/din-8.png'

import zi_1 from '../img/inferior/zi-1.png'
import zin_1 from '../img/inferior/zin-1.png'
import zi_2 from '../img/inferior/zi-2.png'
import zin_2 from '../img/inferior/zin-2.png'
import zi_3 from '../img/inferior/zi-3.png'
import zin_3 from '../img/inferior/zin-3.png'
import zi_4 from '../img/inferior/zi-4.png'
import zin_4 from '../img/inferior/zin-4.png'
import zi_5 from '../img/inferior/zi-5.png'
import zin_5 from '../img/inferior/zin-5.png'
import zi_6 from '../img/inferior/zi-6.png'
import zin_6 from '../img/inferior/zin-6.png'
import zi_7 from '../img/inferior/zi-7.png'
import zin_7 from '../img/inferior/zin-7.png'
import zi_8 from '../img/inferior/zi-8.png'
import zin_8 from '../img/inferior/zin-8.png'

const PieInf = ({ Data, setData }) => {
	

	return (
		<div className="pieInf">
			<div className="pieInfIzqu">
				<img className="piePlantillaInf" src={infIzq} alt="" />
				{/* Inferior Izquierdo */}
				<label htmlFor="zi-7">
					{!Data.zi_7 && <img className="zi_7" src={zin_7} alt="" onClick={() => setData({ ...Data, zi_7: true })} />}
					{Data.zi_7 && <img className="zi_7" src={zi_7} alt="" onClick={() => setData({ ...Data, zi_7: false })} />}
				</label>
				<label htmlFor="zi-1">
					{!Data.zi_1 && <img className="zi_1" src={zin_1} alt="" onClick={() => setData({ ...Data, zi_1: true })} />}
					{Data.zi_1 && <img className="zi_1" src={zi_1} alt="" onClick={() => setData({ ...Data, zi_1: false })} />}
				</label>
				<label htmlFor="zi-2">
					{!Data.zi_2 && <img className="zi_2" src={zin_2} alt="" onClick={() => setData({ ...Data, zi_2: true })} />}
					{Data.zi_2 && <img className="zi_2" src={zi_2} alt="" onClick={() => setData({ ...Data, zi_2: false })} />}
				</label>
				<label htmlFor="zi-3">
					{!Data.zi_3 && <img className="zi_3" src={zin_3} alt="" onClick={() => setData({ ...Data, zi_3: true })} />}
					{Data.zi_3 && <img className="zi_3" src={zi_3} alt="" onClick={() => setData({ ...Data, zi_3: false })} />}
				</label>
				<label htmlFor="zi-4">
					{!Data.zi_4 && <img className="zi_4" src={zin_4} alt="" onClick={() => setData({ ...Data, zi_4: true })} />}
					{Data.zi_4 && <img className="zi_4" src={zi_4} alt="" onClick={() => setData({ ...Data, zi_4: false })} />}
				</label>
				<label htmlFor="zi-5">
					{!Data.zi_5 && <img className="zi_5" src={zin_5} alt="" onClick={() => setData({ ...Data, zi_5: true })} />}
					{Data.zi_5 && <img className="zi_5" src={zi_5} alt="" onClick={() => setData({ ...Data, zi_5: false })} />}
				</label>
				<label htmlFor="zi-6">
					{!Data.zi_6 && <img className="zi_6" src={zin_6} alt="" onClick={() => setData({ ...Data, zi_6: true })} />}
					{Data.zi_6 && <img className="zi_6" src={zi_6} alt="" onClick={() => setData({ ...Data, zi_6: false })} />}
				</label>

				<label htmlFor="zi-8">
					{!Data.zi_8 && <img className="zi_8" src={zin_8} alt="" onClick={() => setData({ ...Data, zi_8: true })} />}
					{Data.zi_8 && <img className="zi_8" src={zi_8} alt="" onClick={() => setData({ ...Data, zi_8: false })} />}
				</label>
			</div>

			<div className="pieInfIzqu">
				<img className="piePlantillaInf" src={infDer} alt="" />
				{/* Inferior Izquierdo */}
				<label htmlFor="di-7">
					{!Data.di_7 && <img className="di_7" src={din_7} alt="" onClick={() => setData({ ...Data, di_7: true })} />}
					{Data.di_7 && <img className="di_7" src={di_7} alt="" onClick={() => setData({ ...Data, di_7: false })} />}
				</label>
				<label htmlFor="di-1">
					{!Data.di_1 && <img className="di_1" src={din_1} alt="" onClick={() => setData({ ...Data, di_1: true })} />}
					{Data.di_1 && <img className="di_1" src={di_1} alt="" onClick={() => setData({ ...Data, di_1: false })} />}
				</label>
				<label htmlFor="di-2">
					{!Data.di_2 && <img className="di_2" src={din_2} alt="" onClick={() => setData({ ...Data, di_2: true })} />}
					{Data.di_2 && <img className="di_2" src={di_2} alt="" onClick={() => setData({ ...Data, di_2: false })} />}
				</label>
				<label htmlFor="di-3">
					{!Data.di_3 && <img className="di_3" src={din_3} alt="" onClick={() => setData({ ...Data, di_3: true })} />}
					{Data.di_3 && <img className="di_3" src={di_3} alt="" onClick={() => setData({ ...Data, di_3: false })} />}
				</label>
				<label htmlFor="di-4">
					{!Data.di_4 && <img className="di_4" src={din_4} alt="" onClick={() => setData({ ...Data, di_4: true })} />}
					{Data.di_4 && <img className="di_4" src={di_4} alt="" onClick={() => setData({ ...Data, di_4: false })} />}
				</label>
				<label htmlFor="di-5">
					{!Data.di_5 && <img className="di_5" src={din_5} alt="" onClick={() => setData({ ...Data, di_5: true })} />}
					{Data.di_5 && <img className="di_5" src={di_5} alt="" onClick={() => setData({ ...Data, di_5: false })} />}
				</label>
				<label htmlFor="di-6">
					{!Data.di_6 && <img className="di_6" src={din_6} alt="" onClick={() => setData({ ...Data, di_6: true })} />}
					{Data.di_6 && <img className="di_6" src={di_6} alt="" onClick={() => setData({ ...Data, di_6: false })} />}
				</label>

				<label htmlFor="di-8">
					{!Data.di_8 && <img className="di_8" src={din_8} alt="" onClick={() => setData({ ...Data, di_8: true })} />}
					{Data.di_8 && <img className="di_8" src={di_8} alt="" onClick={() => setData({ ...Data, di_8: false })} />}
				</label>
			</div>
		</div>
	)
}

export default PieInf
