import React, { useEffect, useState } from 'react'
import FormTextField from '../../../../components/formTextField/FormTextField'
import HistorialPaciente from '../../../paciente/views/historialPaciente/historialPaciente'
import DatosCitaEditar from './nc-section-3-content-editar/nc-section-3-datos-cita-editar'
import MedicamentosCartEditar from './nc-section-3-content-editar/nc-section-3-medicamentos-editar'
import PaquetesCartEditar from './nc-section-3-content-editar/nc-section-3-paquetes-editar'

const NcSection3Editar = ({ setDatosCita, Cita, setLoadState }) => {
	const [Nav, setNav] = useState(1)
	const [ViewHistorial, setViewHistorial] = useState(false)

	const initData = () => {}

	useEffect(() => {}, [])

	return (
		<div className="nc-section-3">
			{/* Data Paciente */}
			<div className="ncs3__PacienteData">
				<FormTextField label={'Paciente:'} name={'paciente'} setVal={() => {}} value={Cita.paciente} readOnly={true} />
				<button className="btn-1" onClick={() => setViewHistorial(true)}>
					Ver Historial
				</button>
			</div>

			{/* Sections */}
			<div className="sectionsBox">
				<div className="sectionsBox__sectionsList">
					<span
						className={Nav === 1 ? 'sectionsList__ListItem active' : 'sectionsList__ListItem'}
						onClick={() => setNav(1)}>
						Datos de la Cita
					</span>
					<span
						className={Nav === 2 ? 'sectionsList__ListItem active' : 'sectionsList__ListItem'}
						onClick={() => setNav(2)}>
						Medicamentos ({Cita.medicamentos.length})
					</span>
					<span
						className={Nav === 3 ? 'sectionsList__ListItem active' : 'sectionsList__ListItem'}
						onClick={() => {
							// setNav(3)
							alert('No disponible')
						}}>
						Paquetes ({Cita.paquetes.length})
					</span>
				</div>
				{Nav === 1 && <DatosCitaEditar Cita={Cita} setDatosCita={setDatosCita} setLoadState={setLoadState} />}
				{Nav === 2 && <MedicamentosCartEditar Cita={Cita} setDatosCita={setDatosCita} setLoadState={setLoadState} />}
				{Nav === 3 && <PaquetesCartEditar Cita={Cita} setDatosCita={setDatosCita} setLoadState={setLoadState} />}
			</div>
		</div>
	)
}

export default NcSection3Editar
