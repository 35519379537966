import React, { useEffect, useState } from 'react'
import { MdTextFields } from 'react-icons/md'
import { getData, setData } from '../../../../controllers/dataController'
import FormTextField from '../../../../components/formTextField/FormTextField'
import FormTitle from '../../../../components/formTitle/FormTitle'

const NuevoPacienteForm = ({ setView, setLoadState, setPaciente = () => {}, close = () => {} }) => {
	const [DataPaciente, setDataPaciente] = useState({
		nombre: '',
		apellidos: '',
		celular: '',
		fechanac: '',
		idTipoDoc: '',
		doc: '',
		diabetico: 0,
		hipertenso: 0,
		alergia: '',
		otros: ''
	})

	const crearPaciente = async () => {
		if (!DataPaciente.nombre) return alert('Ingrese el Campo Nombre')
		if (!DataPaciente.apellidos) return alert('Ingrese el Campo Apellidos')
		if (!DataPaciente.celular) return alert('Ingrese el Campo Celular')
		// if (!DataPaciente.fechanac) return alert('Ingrese el Campo Fecha de Nacimiento')
		if (DataPaciente.idTipoDoc !== '') {
			if (!DataPaciente.doc) return alert('Ingrese el Campo Documento')
			if (DataPaciente.idTipoDoc === 'dni' && DataPaciente.doc.length < 8) return alert('El DNI debe tener 8 digitos')
			if (DataPaciente.idTipoDoc === 'pasaporte' && DataPaciente.doc.length < 12)
				return alert('El PASAPORTE debe tener 12 digitos')
		}
		setLoadState(true)

		localStorage.setItem('nuevo_paciente', JSON.stringify(DataPaciente))

		DataPaciente.apellidos = DataPaciente.apellidos.trimEnd()
		DataPaciente.nombre = DataPaciente.nombre.trimEnd()

		const dataNewPaciente = await setData('paciente', { ...DataPaciente })
		if (dataNewPaciente.error) {
			setLoadState(false)
			return alert(dataNewPaciente.error)
		}

		close()
		setPaciente(dataNewPaciente)
		setView(false)
		setLoadState(false)
		localStorage.removeItem('nuevo_paciente')
	}

	const setVal = (name, val) => setDataPaciente({ ...DataPaciente, [name]: val })

	const initData = async () => {
		if (localStorage.getItem('nuevo_paciente') != null) {
			if (window.confirm('¿Quieres cargar los datos del paciente anterior no guardado?')) {
				setLoadState(true)
				var paciente = JSON.parse(localStorage.getItem('nuevo_paciente'))
				console.log(paciente)
				setDataPaciente({ ...paciente })
				setLoadState(false)
			}
		}
	}

	useEffect(() => {
		initData()
	}, [])

	return (
		<div className="formPopBkg">
			<div className="form formPop">
				{/* Title */}
				<FormTitle setView={setView} close={close} title={'Nuevo Paciente'} />

				<div className="formBody">
					{/* Nombre */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>{'Ingresar Nombres'}</label>
						</div>
						<input
							type="text"
							maxLength={22}
							autoComplete="off"
							defaultValue={DataPaciente.nombre}
							onChange={(e) => {
								e.target.value = e.target.value.trimStart()
								var expresionRegular = /^[A-Za-z\u00C0-\u017F\s]*$/
								if (expresionRegular.test(e.target.value)) {
									setVal('nombre', e.target.value)
								} else {
									e.target.value = DataPaciente.nombre
									alert('Solo ingrese Letras!')
								}
							}}
						/>
					</div>

					{/* Apellido */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>{'Ingresar Apellidos'}</label>
						</div>
						<input
							maxLength={28}
							type="text"
							autoComplete="off"
							defaultValue={DataPaciente.apellidos}
							onChange={(e) => {
								e.target.value = e.target.value.trimStart()
								var expresionRegular = /^[A-Za-z\u00C0-\u017F\s]*$/
								if (expresionRegular.test(e.target.value)) {
									setVal('apellidos', e.target.value)
								} else {
									e.target.value = DataPaciente.apellidos
									alert('Solo ingrese Letras!')
								}
							}}
						/>
					</div>

					{/* Telefono */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>{'Ingresar Celular'}</label>
						</div>
						<input
							maxLength={9}
							autoComplete="off"
							defaultValue={DataPaciente.celular}
							onChange={(e) => {
								e.target.value = e.target.value.trim()
								if (!isNaN(e.target.value)) {
									setVal('celular', e.target.value)
								} else {
									e.target.value = DataPaciente.celular
									alert('Solo ingrese Numeros!')
								}
							}}
						/>
					</div>

					{/* Fecha de nacimiento */}
					<FormTextField
						label={'Ingresar Fecha Nacimiento'}
						value={DataPaciente.fechanac}
						small={' (opcional)'}
						name={'fechanac'}
						setVal={setVal}
						type="date"
					/>

					{/* Tipo Doc */}
					<div className="form__TextField select">
						<label>
							Tipo de Documento <small>(opcional)</small>
						</label>
						<select
							name="tipodoc"
							defaultValue={DataPaciente.idTipoDoc}
							onChange={(e) => setDataPaciente({ ...DataPaciente, idTipoDoc: e.target.value })}>
							<option selected={DataPaciente.idTipoDoc == '' && true} value="">
								-- Seleccione --
							</option>
							<option value="">No aplica</option>
							<option selected={DataPaciente.idTipoDoc == 'dni' && true} value="dni">
								dni
							</option>
							<option selected={DataPaciente.idTipoDoc == 'pasaporte' && true} value="pasaporte">
								pasaporte
							</option>
						</select>
					</div>

					{/* Doc */}
					{DataPaciente.idTipoDoc !== '' && (
						<div className="form__TextField">
							<div className="form__TextFieldLabel">
								<MdTextFields className="iconLabel" />
								<label>Ingresar Documento</label>
							</div>
							<input
								maxLength={DataPaciente.idTipoDoc === 'dni' ? 8 : 12}
								autoComplete="off"
								type="text"
								defaultValue={DataPaciente.doc}
								onChange={(e) => {
									e.target.value = e.target.value.trim()
									if (!isNaN(e.target.value)) {
										setVal('doc', e.target.value)
									} else {
										e.target.value = DataPaciente.doc
										alert('Solo ingrese Numeros!')
									}
								}}
							/>
						</div>
					)}

					{/* Diabetico */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>Diabetico:</label>
						</div>
						<div className="chooseDiv">
							<div
								className={!DataPaciente.diabetico ? 'btnChoose' : 'btnChoose btnCS'}
								onClick={() => setDataPaciente({ ...DataPaciente, diabetico: 1 })}>
								Si
							</div>
							<div
								className={!DataPaciente.diabetico ? 'btnChoose btnCS' : 'btnChoose'}
								onClick={() => setDataPaciente({ ...DataPaciente, diabetico: 0 })}>
								No
							</div>
						</div>
					</div>

					{/* Hipertenso */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>Hipertenso:</label>
						</div>
						<div className="chooseDiv">
							<div
								className={!DataPaciente.hipertenso ? 'btnChoose' : 'btnChoose btnCS'}
								onClick={() => setDataPaciente({ ...DataPaciente, hipertenso: 1 })}>
								Si
							</div>
							<div
								className={!DataPaciente.hipertenso ? 'btnChoose btnCS' : 'btnChoose'}
								onClick={() => setDataPaciente({ ...DataPaciente, hipertenso: 0 })}>
								No
							</div>
						</div>
					</div>

					{/* Alergia  */}
					<FormTextField
						label={'Medicamentos al que es alergico:'}
						value={DataPaciente.alergia}
						small={' (opcional)'}
						name={'alergia'}
						type="text"
						setVal={setVal}
					/>

					{/* Otros  */}
					<FormTextField
						label={'Otras Alergias:'}
						value={DataPaciente.otros}
						small={' (opcional)'}
						name={'otros'}
						type="text"
						setVal={setVal}
					/>

					{/* Action Buttons */}
					<div className="form__groupBtns">
						<button
							className="form__btnCancel"
							onClick={() => {
								close()
								setView(false)
							}}>
							Cancelar
						</button>
						<button className="form__btnSuccess" onClick={() => crearPaciente()}>
							Confirmar
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default NuevoPacienteForm
