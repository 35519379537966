import React, { useEffect, useState } from 'react'
import { MdTextFields } from 'react-icons/md'
import { getData, setData, updateData } from '../../../../controllers/dataController'
import FormTextField from '../../../../components/formTextField/FormTextField'
import FormTitle from '../../../../components/formTitle/FormTitle'

const EditarPacienteForm = ({ setView, setLoadState, Paciente }) => {
	const [DataPaciente, setDataPaciente] = useState({ ...Paciente })
	const [TipoDocs, setTipoDoc] = useState([])

	const crearPaciente = async () => {
		if (!DataPaciente.nombre) return alert('Ingrese el Campo Nombre')
		if (!DataPaciente.apellidos) return alert('Ingrese el Campo Apellidos')
		if (!DataPaciente.celular) return alert('Ingrese el Campo Celular')
		if (!DataPaciente.fechanac) return alert('Ingrese el Campo Fecha de Nacimiento')
		if (!DataPaciente.idTipoDoc) return alert('Seleccione el tipo de Documento')
		if (!DataPaciente.doc) return alert('Ingrese el Campo Documento')
		setLoadState(true)

		const data = { ...DataPaciente, token: localStorage.getItem('token') }

		const dataNewPaciente = await updateData('paciente', data, Paciente.idPaciente)

		console.log(data)

		setView(false)
		setLoadState(false)
	}

	const setVal = (name, val) => setDataPaciente({ ...DataPaciente, [name]: val })

	const initData = async () => {
		const dataTipoDocs = await getData('tipodoc')
		setTipoDoc(dataTipoDocs)
	}

	useEffect(() => {
		initData()
	}, [])

	return (
		<div className="formPopBkg">
			<div className="form formPop">
				{/* Title */}
				<FormTitle setView={setView} title={'Editar Paciente'} />

				<div className="formBody">
					{/* Nombre */}
					<FormTextField value={DataPaciente.nombre} label={'Ingresar Nombres'} name={'nombre'} setVal={setVal} />
					{/* Apellido */}
					<FormTextField
						value={DataPaciente.apellidos}
						label={'Ingresar Apellidos'}
						name={'apellidos'}
						setVal={setVal}
					/>
					{/* Telefono */}
					<FormTextField
						value={DataPaciente.celular}
						label={'Ingresar Celular'}
						name={'celular'}
						setVal={setVal}
						type="phone"
					/>
					{/* Telefono */}
					<FormTextField
						value={DataPaciente.fechanac}
						label={'Ingresar Fecha Nacimiento'}
						small={' (opcional)'}
						name={'fechanac'}
						setVal={setVal}
						type="date"
					/>

					{/* Tipo Doc */}
					<div className="form__TextField select">
						<label>
							Tipo de Documento <small>(opcional)</small>
						</label>
						<select name="tipodoc" onChange={(e) => setDataPaciente({ ...DataPaciente, idTipoDoc: e.target.value })}>
							<option value="" selected={DataPaciente.idTipoDoc == '' && true}>-- Seleccione --</option>
							<option selected={DataPaciente.idTipoDoc == 'dni' && true} value="dni">dni</option>
							<option selected={DataPaciente.idTipoDoc == 'pasaporte' && true} value="pasaporte">pasaporte</option>
							<option value="">No aplica</option>
						</select>
					</div>

					{/* Doc */}
					{DataPaciente.idTipoDoc !== '' && (
						<FormTextField value={DataPaciente.doc} label={'Ingresar Documento'} name={'doc'} setVal={setVal} />
					)}

					{/* Diabetico */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>Diabetico:</label>
						</div>
						<div className="chooseDiv">
							<div
								className={!DataPaciente.diabetico ? 'btnChoose' : 'btnChoose btnCS'}
								onClick={() => setDataPaciente({ ...DataPaciente, diabetico: 1 })}>
								Si
							</div>
							<div
								className={!DataPaciente.diabetico ? 'btnChoose btnCS' : 'btnChoose'}
								onClick={() => setDataPaciente({ ...DataPaciente, diabetico: 0 })}>
								No
							</div>
						</div>
					</div>

					{/* Hipertenso */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>Hipertenso:</label>
						</div>
						<div className="chooseDiv">
							<div
								className={!DataPaciente.hipertenso ? 'btnChoose' : 'btnChoose btnCS'}
								onClick={() => setDataPaciente({ ...DataPaciente, hipertenso: 1 })}>
								Si
							</div>
							<div
								className={!DataPaciente.hipertenso ? 'btnChoose btnCS' : 'btnChoose'}
								onClick={() => setDataPaciente({ ...DataPaciente, hipertenso: 0 })}>
								No
							</div>
						</div>
					</div>

					{/* Alergia  */}
					<FormTextField
						value={DataPaciente.alergia}
						label={'Medicamentos al que es alergico:'}
						small={' (opcional)'}
						name={'alergia'}
						setVal={setVal}
					/>

					{/* Otros  */}
					<FormTextField
						value={DataPaciente.otros}
						small={' (opcional)'}
						label={'Otras Alergias:'}
						name={'otros'}
						setVal={setVal}
					/>

					{/* Action Buttons */}
					<div className="form__groupBtns">
						<button className="form__btnCancel" onClick={() => setView(false)}>
							Cancelar
						</button>
						<button className="form__btnSuccess" onClick={() => crearPaciente()}>
							Actualizar
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EditarPacienteForm
