import React from 'react'

const CbxField = ({ label, setVal, name, options = [] }) => {
	return (
		<div className="txtField">
			<label className="txtField__label">{label}:</label>
			<select className="selectField" onChange={(i) => setVal(name, i.currentTarget.value)}>
				<option value={''}>---Seleccione---</option>
				{options.map((i) => (
					<option value={i}>{i}</option>
				))}
			</select>
		</div>
	)
}

export default CbxField
