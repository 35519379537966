import React, { useEffect, useState } from 'react'
import './VentaMedicamentos.css'
import ListWithCategories from '../../../../components/listWithCategories/listWithCategories'
import ListCart from '../../../../components/listCart/listCart'
import { getData, setData } from '../../../../controllers/dataController'
import { AiFillPlusCircle, AiOutlineMedicineBox } from 'react-icons/ai'
import { MdTextFields } from 'react-icons/md'
import { IoCloseCircleSharp } from 'react-icons/io5'
import FormTextField from '../../../../components/formTextField/FormTextField'
import { getFecha, getTime } from '../../../../controllers/fechaController'

const VentaMedicamentos = ({ setLoadState, setRoute }) => {
	const [Categorias, setCategorias] = useState([])
	const [Medicamentos, setMedicamentos] = useState([])
	const [Cart, setCart] = useState([])
	const [Pagar, setPagar] = useState(false)
	const [Fecha, setFecha] = useState(getFecha())

	const updateCart = (item, cant = 1) => {
		setLoadState(true)
		var _cart = []

		if (item === 'delete') {
			_cart = Cart
			_cart.splice(cant, 1)
			setCart([..._cart])
			return setLoadState(false)
		}

		if (cant <= 0) {
			alert('La cantidad tiene que ser mayor a "0"')
			return setLoadState(false)
		}

		if (Cart.lenght === 0) {
			item.cant = cant
			_cart.push(item)
		} else {
			_cart = Cart
			const elementIndex = _cart.findIndex((obj) => obj.idMedicamento === item.idMedicamento)
			if (elementIndex !== -1) {
				// Update
				_cart[elementIndex].cant = cant
			} else {
				// Add
				item.cant = cant
				_cart.push(item)
			}
		}
		// Update in DataCita
		setCart([..._cart])
		setLoadState(false)
	}

	const initData = async () => {
		setLoadState(true)
		// Get categorias
		const dataCat = await getData('categoria')
		setCategorias(dataCat)
		// Get Medicamentos
		const dataMed = await getData('medicamento')
		setMedicamentos(dataMed)
		setLoadState(false)
	}

	useEffect(() => {
		initData()
	}, [Cart])

	return (
		<div className="page ventaMed">
			{Pagar !== false && <PagarCita item={Cart} setView={setPagar} fecha={Fecha} setRoute={setRoute} />}

			<div className="page__pageTitle">
				<AiOutlineMedicineBox className="icon-links" /> <span className="pageTitle__span">Venta de Medicamentos</span>
			</div>

			<div className="ventaMed fechaFormTxtField">
				<FormTextField
					value={Fecha}
					label={'Fecha'}
					name={'fecha'}
					type="date"
					setVal={(name, value) => setFecha(value)}
				/>
			</div>

			<div className="sectionsBox__Body sectionBox__Medicamentos">
				{/* List with categories */}
				{Medicamentos.length > 0 && (
					<ListWithCategories Categories={Categorias} Data={Medicamentos} setD={updateCart} />
				)}

				{/* List Cart */}
				<ListCart Data={Cart} setD={updateCart} />

				{/* Detalle Cart */}
				<div className="detailCart">
					<div className="total">
						<div className="total__text">
							<label className="total_label">Total</label>
						</div>
						<input
							className="total__show"
							value={Cart.reduce((acumulador, it) => acumulador + it.cant * it.cuv, 0)}
							readOnly={true}
						/>
					</div>
				</div>
			</div>

			{Cart.reduce((acumulador, it) => acumulador + it.cant * it.cuv, 0) > 0 && (
				<button className="btn-1" onClick={() => setPagar(Cart)}>
					Pagar
				</button>
			)}
		</div>
	)
}

export default VentaMedicamentos

const PagarCita = ({ item, setView, fecha, setRoute }) => {
	const [DataE, setDataE] = useState({ metodoPago: '' })

	const Pagar = async () => {
		if (DataE.metodoPago === '') return alert('Seleccione un metodo de pago')
		const dataset = {
			...DataE,
			medicamentos: item,
			token: localStorage.getItem('token'),
			total: item.reduce((acumulador, it) => acumulador + it.cant * it.cuv, 0),
			timestamp: fecha + ' ' + getTime()
		}

		const resp = await setData('medicamento/venta', dataset)

		if (resp.exception) {
			alert('Error en el registro, vuelva a intentarlo.')
			return window.location.reload()
		}

		if (resp.error) {
			if (resp.error === 'Falta token.') {
				alert('Error en el registro, vuelva a intentarlo')
				return window.location.reload()
			}
			return alert(resp.error)
		}

		console.log(resp)
		setRoute('ver-ventas-medicamentos')
	}

	return (
		<div className="bkg-nPC">
			<div className="nEntrada form formPop">
				{/* Title */}
				<div className="form__title">
					<AiFillPlusCircle className="form__titleIcon" />
					<span className="form__titleText">Pagar</span>
					<button className="form__titleActionClose" onClick={() => setView(false)}>
						<IoCloseCircleSharp />
					</button>
				</div>

				<div className="formBody">
					{/* ------------------ */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>TOTAL a PAGAR:</label>
						</div>
						<input
							type="number"
							className="ninputT"
							readOnly
							value={item.reduce((acumulador, it) => acumulador + it.cant * it.cuv, 0)}
						/>
					</div>
					{/* ------------------ */}

					{/* ------------------ */}
					<div className="form__TextField">
						<div className="form__TextFieldLabel">
							<MdTextFields className="iconLabel" />
							<label>Medio de Pago:</label>
						</div>
						<select onChange={(e) => setDataE({ ...DataE, metodoPago: e.target.value })}>
							<option value={''}>--- Seleccione ---</option>
							<option value="efectivo">Efectivo</option>
							<option value="yape">Yape</option>
							<option value="transferencia">Transferencia</option>
							<option value="transferencia">POS</option>
						</select>
					</div>
					{/* ------------------ */}

					{/* Action Buttons */}
					<div className="form__groupBtns">
						<button className="form__btnCancel" onClick={() => setView(false)}>
							Cancelar
						</button>
						<button className="form__btnSuccess" onClick={() => Pagar()}>
							Confirmar Pago
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
